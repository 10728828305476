import { GetterTree } from 'vuex';
import { RootState } from '@/app/store/root.models';
import { AuthState } from './auth.models';

export const authGettersTypes = {
  isLogged: 'isLogged',
  isAuthLoading: 'isAuthLoading',
} as const;

const getters: GetterTree<AuthState, RootState> = {
  [authGettersTypes.isLogged]: state => !!state.token,
  [authGettersTypes.isAuthLoading]: state => state.loading,
};

export default getters;
