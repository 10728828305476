import { DefineTypes, DefineMutations } from '@/app/store/store.helper';
import { RootState } from '@/app/store/root.models';
import { UserRole, UserState } from './user.models';

export interface UserMutations {
  setUser: UserState['user'];
  setLoading: UserState['loading'];
  setAuthenticated: UserState['authenticated'];
  setRedirect: UserState['redirect'];
  setProject: UserState['project'];
}

export const userMutationsTypes: DefineTypes<UserMutations> = {
  setUser: payload => ({ type: 'setUser', payload }),
  setLoading: payload => ({ type: 'setLoading', payload }),
  setAuthenticated: payload => ({ type: 'setAuthenticated', payload }),
  setRedirect: payload => ({ type: 'setRedirect', payload }),
  setProject: payload => ({ type: 'setProject', payload }),
};

const mutations: DefineMutations<UserMutations, UserState, RootState> = {
  setUser(state, { payload }) {
    state.user = { ...payload, role: payload.role || UserRole.BASE };
  },
  setLoading(state, { payload }) {
    state.loading = payload;
  },

  setAuthenticated(state, { payload }) {
    state.authenticated = payload;
  },
  setRedirect(state, { payload }) {
    state.redirect = payload;
  },
  setProject(state, { payload }) {
    state.project = payload;
  },
};

export default mutations;
