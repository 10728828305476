import { DefineTypes, DefineActions } from '@/app/store/store.helper';
import { RootState } from '@/app/store/root.models';
import Axios from 'axios';
import { BASE_URL_MANAGER } from '@/config';
import router from '@/app/router';
import { UserState, UserRole, IUser } from './user.models';
import { userMutationsTypes } from './user.mutations';
import { agentActionsTypes } from '../agent/agent.actions';
import { authActionsTypes } from '../auth/auth.actions';
import { Commit, Dispatch } from 'vuex/types/index';
import { AdminActionsTypes } from '../admin/admin.actions';

export interface UserActions {
  loadUser: UserState['user']['_id'];
  dispatchRouteByRole: Pick<UserState['user'], '_id' | 'role' | 'company'>;
}

export const userActionsTypes: DefineTypes<UserActions> = {
  loadUser: payload => ({ type: 'loadUser', payload }),
  dispatchRouteByRole: payload => ({ type: 'dispatchRouteByRole', payload }),
};

const actions: DefineActions<UserActions, UserState, RootState> = {
  async loadUser({ commit, dispatch }, { payload }) {
    commit(userMutationsTypes.setLoading(true));
    const token = localStorage.getItem('token:wizard');

    if (!token) {
      await redirectToLogin(commit, dispatch);
      return;
    }

    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const resquest = await Axios.get(`${BASE_URL_MANAGER}/user/${payload}`);
    const { user } = <{ user: IUser }>resquest.data;
    if (!user) {
      await redirectToLogin(commit, dispatch);
      return;
    }
    await handleResponse(resquest, commit, dispatch);
    commit(userMutationsTypes.setLoading(false));
  },
  async dispatchRouteByRole({ dispatch }, { payload }) {
    if (payload.role === UserRole.AGENT) {
      router.push({ name: 'loading' });
      await dispatch(agentActionsTypes.loadAgent(payload._id));
    } else if (
      payload.role === UserRole.ADMIN ||
      payload.role === UserRole.BASE
    ) {
      await dispatch(AdminActionsTypes.getProject(payload.company!._id));
      await dispatch(AdminActionsTypes.getAgents(payload.company!._id));
      await dispatch(AdminActionsTypes.setUsernames(payload.company!._id));
      await router.push({ name: 'admin' });
    } else {
      await router.push({ name: 'home' });
    }
  },
};

async function redirectToLogin(commit: Commit, dispatch: Dispatch) {
  await dispatch(authActionsTypes.logout());
}

async function handleResponse(resp: any, commit: Commit, dispatch: Dispatch) {
  const { user } = <{ user: IUser }>resp.data;

  commit(userMutationsTypes.setLoading(false));

  if (!user) {
    await redirectToLogin(commit, dispatch);
    return;
  }
  commit(userMutationsTypes.setUser({ ...user, login: true }));
  const project = `${BASE_URL_MANAGER}/project/${
    user.company!.projects[0]._id
  }`;

  await Axios(project)
    .then(async resp => {
      const { project } = <{ project: any }>resp.data;
      commit(userMutationsTypes.setProject(project));
    })
    .then(async () => {
      await dispatch(userActionsTypes.dispatchRouteByRole(user));
    });
}

export default actions;
