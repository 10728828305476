import { Component } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import { store, storeTypes } from './store';
import { userTypes } from './store/modules/user';
import { parseJwt } from './store/modules/auth/auth.helper';

import axios, { AxiosError } from 'axios';
import 'animate.css';
@Component({
  name: 'app',
})
export default class App extends VueWizard {
  public user: any;

  mounted(): void {
    axios.interceptors.response.use(
      (response: any) => {
        return response;
      },
      async (error: AxiosError) => {
        if (error.message === 'Operation canceled by the user.') {
          return Promise.reject(error);
        }

        if (error.response!.status === 401) {
          await store.dispatch(storeTypes.auth.actions.logout());
        }
        if (error.response && error.response.data) {
          return Promise.reject(error.response.data);
        }
        if (error.message) {
          return Promise.reject(error.message);
        }
        return Promise.reject(error);
      },
    );
  }

  async created(): Promise<any> {
    this.user = store.state.user;
    const { token } = store.state.auth!;

    if (token) {
      const userDecode = parseJwt(token);

      let current_time = Date.now() / 1000;
      if (current_time > userDecode.exp) {
        await store.dispatch(storeTypes.auth.actions.logout());
      } else {
        await store.dispatch(userTypes.actions.loadUser(userDecode._id));
        //  await store.dispatch(storeTypes.auth.actions.initExpirationChecker(userDecode.exp));
      }
    }
  }
}
