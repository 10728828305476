import {
  Conversation,
  ConversationNewEntryQueue,
  Interaction,
} from '@/app/store/modules/conversation/conversation.models';

interface FiltersConfig {
  label: string;
  value: string;
  icon: string;
  config: [
    {
      name_filter: string;
      type_filter: string;
      source: string;
      selectedOption: any;
    },
  ];
}
export interface AgentState {
  loadingConversation: boolean;
  tabSelected: string; // 1: Queue, 2: Open, 3: Closed
  orderQueue: number;
  orderClose: number;
  orderOpen: number;
  orderAuto: number;
  pageQueue: number;
  pageClose: number;
  pageOpen: number;
  pageAuto: number;
  agent: IAgent;
  loadingQueue: boolean;
  loadingClose: boolean;
  loadingOpen: boolean;
  loadingAuto: boolean;
  agentOpenConversations: Conversation[];
  agentOpenTotalConversations: number;
  agentCloseConversations: Conversation[];
  agentCloseTotalConversations: number;
  agentQueue: ConversationNewEntryQueue[];
  agentQueueTotal: number;
  agentAutoConversations: ConversationNewEntryQueue[];
  agentAutoTotalConversations: number;
  agentQueuePerPage: number;
  agentSockets: any[];
  interactions: Interaction[];
  actualPage: number;
  totalPages: number;
  transferredToAgent: { agent: any; conversation: Conversation } | null;
  agentOpenConversationsFiltered: Conversation[];
  agentCloseConversationsFiltered: Conversation[];
  agentQueueFiltered: Conversation[];
  agentAutoConversationsFiltered: Conversation[];
  agentOpenTotalConversationsFiltered: number;
  agentCloseTotalConversationsFiltered: number;
  agentQueueTotalFiltered: number;
  agentAutoTotalConversationsFiltered: number;
  filterSelectedOpen: FiltersConfig;
  filterSelectedClose: FiltersConfig;
  filterSelectedQueue: FiltersConfig;
  filterSelectedAuto: FiltersConfig;
}

export const SOCKET_EVENTS = {
  NEW_ENTRY: 'NEW_ENTRY',
  REMOVE_ENTRY: 'REMOVE_ENTRY',
  AGENT: 'AGENT',
  NEW_MESSAGE: 'NEW_MESSAGE',
  DELETE_CONVERSATION: 'DELETE_CONVERSATION',
  DUPLICATED_SESSION: 'DUPLICATED_SESSION',
  AWATING_RESPONSE: 'AWATING_RESPONSE',
  TRANSFERRED_CONVERSATION: 'TRANSFERRED_CONVERSATION',
  UPDATE_INTERACTION: 'UPDATE_INTERACTION',
  UPDATE_CONVERSATION: 'UPDATE_CONVERSATION',
  NEW_ENTRY_AUTO: 'NEW_ENTRY_AUTO',
  NEW_MESSAGE_AUTO: 'NEW_MESSAGE_AUTO',
};

export interface IAgent {
  _id: string;
  subscriptions: string[];
  user: string;
}

export type ExtendedAgentState = { agent?: AgentState };

export const initialAgentState: AgentState = {
  loadingConversation: true,
  tabSelected: 'encola',
  orderQueue: 1,
  orderClose: -1,
  orderOpen: -1,
  orderAuto: -1,
  pageQueue: 0,
  pageClose: 0,
  pageOpen: 0,
  pageAuto: 0,
  agent: { _id: '', subscriptions: [], user: '' },
  loadingQueue: false,
  loadingClose: false,
  loadingOpen: false,
  loadingAuto: false,
  agentOpenConversations: [],
  agentOpenTotalConversations: 0,
  agentCloseConversations: [],
  agentCloseTotalConversations: 0,
  agentQueue: [],
  agentQueueTotal: 0,
  agentAutoConversations: [],
  agentAutoTotalConversations: 0,
  agentQueuePerPage: 10,
  agentSockets: [],
  interactions: [],
  actualPage: 0,
  totalPages: 0,
  transferredToAgent: null,
  agentOpenConversationsFiltered: [],
  agentCloseConversationsFiltered: [],
  agentQueueFiltered: [],
  agentAutoConversationsFiltered: [],
  agentOpenTotalConversationsFiltered: 0,
  agentCloseTotalConversationsFiltered: 0,
  agentQueueTotalFiltered: 0,
  agentAutoTotalConversationsFiltered: 0,
  filterSelectedOpen: {
    label: 'Todos',
    value: 'all',
    icon: 'mdi-filter-outline',
    config: [
      {
        name_filter: 'Todos',
        type_filter: 'all',
        source: 'mdi-filter-outline',
        selectedOption: [],
      },
    ],
  },
  filterSelectedClose: {
    label: 'Todos',
    value: 'all',
    icon: 'mdi-filter-outline',
    config: [
      {
        name_filter: 'Todos',
        type_filter: 'all',
        source: 'mdi-filter-outline',
        selectedOption: [],
      },
    ],
  },
  filterSelectedQueue: {
    label: 'Todos',
    value: 'all',
    icon: 'mdi-filter-outline',
    config: [
      {
        name_filter: 'Todos',
        type_filter: 'all',
        source: 'mdi-filter-outline',
        selectedOption: [],
      },
    ],
  },
  filterSelectedAuto: {
    label: 'Todos',
    value: 'all',
    icon: 'mdi-filter-outline',
    config: [
      {
        name_filter: 'Todos',
        type_filter: 'all',
        source: 'mdi-filter-outline',
        selectedOption: [],
      },
    ],
  },
};
