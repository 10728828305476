import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './root.models';
import { user, userTypes } from './modules/user';
import { auth, authTypes } from './modules/auth';
import { agent, agentTypes } from './modules/agent';
import { conversationTypes, conversation } from './modules/conversation/index';
import { filtersTypes, filters } from './modules/filters';
import { admin, adminTypes } from './modules/admin';
declare global {
  interface Window {
    Intercom: any;
    intercomSettings: any;
    attachEvent: any;
    fbAsyncInit: any;
    dataLayer: any;
    FB: any;
    $: any; // 👈️ turn off type checking
  }
}
// let FB = window.FB;
Vue.use(Vuex);
const rootStore: StoreOptions<RootState> = {
  strict: true,
  modules: {
    user,
    auth,
    agent,
    conversation,
    filters,
    admin,
  },
};

export const rootTypes = {};

export const storeTypes = {
  root: rootTypes,
  user: userTypes,
  auth: authTypes,
  agent: agentTypes,
  conversation: conversationTypes,
  filters: filtersTypes,
  admin: adminTypes,
};

export * from './root.models';
export const store = new Vuex.Store<RootState>(rootStore);
