export const AuthTokenName = 'token:wizard';
export const FacebookTokenName = 'token:facebook';

export interface AuthState {
  token: string;
  tokenFacebook: string;
  loading: boolean;
}

export type ExtendedAuthState = { auth?: AuthState };
export const initialAuthState: AuthState = {
  token: localStorage.getItem(AuthTokenName) || '',
  tokenFacebook: localStorage.getItem(FacebookTokenName) || '',
  loading: false,
};
