import { DefineTypes, DefineActions } from '@/app/store/store.helper';
import { RootState } from '@/app/store/root.models';
import Axios from 'axios';
import { BASE_URL_MANAGER, BASE_URL_ORCHESTATOR } from '@/config';
import { agentMutationsTypes } from '../agent/agent.mutations';
import {
  ConversationState,
  Conversation,
  ConversationNewEntryQueue,
  ConversationMessageElement,
  Interaction,
  ReturnOldConversation,
  BusinessStatus,
  ContactData,
  MessageType,
} from './conversation.models';
import { conversationMutationsTypes } from './conversation.mutations';
import { store, storeTypes } from '@/app/store';
const _ = require('lodash');

export interface AgentActions {
  closeConversation: undefined;
  closeSelectedConversation: string[];
  setTotalCloseConversation: number;
  grebConversation: ConversationNewEntryQueue;
  sendMessage: ConversationMessageElement;
  returnConversation: ReturnOldConversation;
  shareSendMessage: ConversationMessageElement;
  changeConversationStatus: BusinessStatus;
  changeCurrentConversationContactData: ContactData;
  transferConversationToAgent: string;
  transferConversationToSegment: string;
  setCurrentConversations: Conversation;
  setNewInteractions: number;

  closeAllConversationQueue: undefined;
  closeAllConversationOpen: undefined;
  closeAllConversationAuto: undefined;
}

export const conversationActionsTypes: DefineTypes<AgentActions> = {
  setTotalCloseConversation: payload => ({
    type: 'setTotalCloseConversation',
    payload,
  }),

  closeConversation: payload => ({
    type: 'closeConversation',
    payload,
  }),
  closeSelectedConversation: payload => ({
    type: 'closeSelectedConversation',
    payload,
  }),
  changeConversationStatus: payload => ({
    type: 'changeConversationStatus',
    payload,
  }),
  grebConversation: payload => ({
    type: 'grebConversation',
    payload,
  }),
  sendMessage: payload => ({
    type: 'sendMessage',
    payload,
  }),
  returnConversation: payload => ({
    type: 'returnConversation',
    payload,
  }),
  shareSendMessage: payload => ({
    type: 'shareSendMessage',
    payload,
  }),
  transferConversationToAgent: payload => ({
    type: 'transferConversationToAgent',
    payload,
  }),
  transferConversationToSegment: payload => ({
    type: 'transferConversationToSegment',
    payload,
  }),
  changeCurrentConversationContactData: payload => ({
    type: 'changeCurrentConversationContactData',
    payload,
  }),
  setCurrentConversations: payload => ({
    type: 'setCurrentConversations',
    payload,
  }),
  setNewInteractions: payload => ({
    type: 'setNewInteractions',
    payload,
  }),
  closeAllConversationQueue: payload => ({
    type: 'closeAllConversationQueue',
  }),
  closeAllConversationOpen: payload => ({
    type: 'closeAllConversationOpen',
  }),
  closeAllConversationAuto: payload => ({
    type: 'closeAllConversationAuto',
  }),
};

const actions: DefineActions<AgentActions, ConversationState, RootState> = {
  setTotalCloseConversation({ commit, state, rootState }) {
    commit(agentMutationsTypes.setOpenTotalConversation());
  },
  async closeConversation({ commit, state, rootState }) {
    const agentId = rootState.agent!.agent._id;
    const conversationId = state.currentConversation._id;

    if (agentId && conversationId) {
      const response = await Axios(
        `${BASE_URL_ORCHESTATOR}/agent/${agentId}/conversation/close/${conversationId}`,
      );

      if (response.data.success) {
        const conversationStatus = state.currentConversation.status;

        if (['ACTIVE', 'TRANSFERED'].includes(conversationStatus)) {
          commit(agentMutationsTypes.setOpenTotalConversation());
        }

        if (['IN_QUEUE', 'AUTO'].includes(conversationStatus)) {
          const queueConversations = rootState.agent!.agentQueueTotal - 1;
          const queueConversationsFiltered =
            rootState.agent!.agentQueueTotalFiltered - 1;

          commit(agentMutationsTypes.setQueueTotal(queueConversations));
          commit(
            agentMutationsTypes.setFilteredConversationsQueueTotal(
              queueConversationsFiltered,
            ),
          );
        }

        const conversationData = {
          status: conversationStatus,
          distribution_company: '',
          id: conversationId,
          phone: state.currentConversation.currentConsumer,
          projectId: state.currentConversation.project || '',
        };

        commit(
          agentMutationsTypes.removeConversationQueueFiltered(conversationData),
        );
        commit(
          agentMutationsTypes.setAgentCloseConversations([
            { ...state.currentConversation, isClosed: true },
          ]),
        );
        commit(agentMutationsTypes.removeConversationOpen(conversationId));
        commit(agentMutationsTypes.removeConversationAuto(conversationId));
        commit(
          agentMutationsTypes.setCurrentConversationInConversations(
            <Conversation>{},
          ),
        );

        const emptyConversation = {
          currentAgent: '',
          currentChannel: '',
          currentConsumer: '',
          currentInputInteraction: [],
          currentTopic: '',
          interactions: [],
          _id: '',
          newMessage: 0,
          variables: {},
          contactData: {},
          newinteractions: 0,
          updatedAt: '',
          createdAt: '',
          status: '',
          checked: false,
        };

        commit(
          conversationMutationsTypes.setCurrentConversation(emptyConversation),
        );
        commit(
          agentMutationsTypes.removeConversationQueue(
            state.currentConversation.currentConsumer,
          ),
        );
      }
    }
  },
  async closeSelectedConversation({ commit, state, rootState }, { payload }) {
    const agentId = rootState.agent!.agent._id;
    const conversations = payload;

    if (agentId && conversations) {
      for (const conversation of conversations) {
        console.log('here', conversation);

        const response = await Axios(
          `${BASE_URL_ORCHESTATOR}/agent/${agentId}/conversation/close/${conversation}`,
        );

        if (response.data.success) {
          const conversationStatus = state.currentConversation.status;

          if (['ACTIVE', 'TRANSFERED'].includes(conversationStatus)) {
            commit(agentMutationsTypes.setOpenTotalConversation());
          }

          if (['IN_QUEUE', 'AUTO'].includes(conversationStatus)) {
            const queueConversations = rootState.agent!.agentQueueTotal - 1;
            const queueConversationsFiltered =
              rootState.agent!.agentQueueTotalFiltered - 1;

            commit(agentMutationsTypes.setQueueTotal(queueConversations));
            commit(
              agentMutationsTypes.setFilteredConversationsQueueTotal(
                queueConversationsFiltered,
              ),
            );
          }

          const conversationData = {
            status: conversationStatus,
            distribution_company: '',
            id: conversation,
            phone: state.currentConversation.currentConsumer,
            projectId: state.currentConversation.project || '',
          };

          commit(
            agentMutationsTypes.removeConversationQueueFiltered(
              conversationData,
            ),
          );
          commit(
            agentMutationsTypes.setAgentCloseConversations([
              { ...state.currentConversation, isClosed: true },
            ]),
          );
          commit(agentMutationsTypes.removeConversationOpen(conversation));
          commit(agentMutationsTypes.removeConversationAuto(conversation));
          commit(
            agentMutationsTypes.setCurrentConversationInConversations(
              <Conversation>{},
            ),
          );

          const emptyConversation = {
            currentAgent: '',
            currentChannel: '',
            currentConsumer: '',
            currentInputInteraction: [],
            currentTopic: '',
            interactions: [],
            _id: '',
            newMessage: 0,
            variables: {},
            contactData: {},
            newinteractions: 0,
            updatedAt: '',
            createdAt: '',
            status: '',
            checked: false,
          };

          commit(
            conversationMutationsTypes.setCurrentConversation(
              emptyConversation,
            ),
          );
          commit(
            agentMutationsTypes.removeConversationQueue(
              state.currentConversation.currentConsumer,
            ),
          );
        }
      }
    }
  },
  async transferConversationToAgent(
    { commit, rootState, state },
    { payload: agentId },
  ) {
    try {
      const response = await Axios.post(
        `${BASE_URL_ORCHESTATOR}/agent/${
          rootState.agent!.agent._id
        }/conversation/transferToAgent/${
          rootState.conversation!.currentConversation._id
        }`,
        { toTransferAgent: agentId },
      );

      if (
        state.currentConversation.status === 'IN_QUEUE' ||
        state.currentConversation.status === 'AUTO'
      ) {
        commit(
          agentMutationsTypes.setQueueTotal(
            rootState.agent!.agentQueueTotal - 1,
          ),
        );
      }
      if (
        state.currentConversation.status === 'ACTIVE' ||
        state.currentConversation.status === 'TRANSFERED'
      ) {
        commit(agentMutationsTypes.setOpenTotalConversation());
      }

      commit(
        agentMutationsTypes.removeConversationOpen(
          state.currentConversation._id,
        ),
      );
      commit(
        agentMutationsTypes.removeConversationQueue(
          state.currentConversation.currentConsumer,
        ),
      );
      commit(
        conversationMutationsTypes.setCurrentConversation({
          currentAgent: '',
          currentChannel: '',
          currentConsumer: '',
          currentInputInteraction: [],
          currentTopic: '',
          interactions: [],
          _id: '',
          newMessage: 0,
          variables: {},
          contactData: {},
          newinteractions: 0,
          updatedAt: '',
          createdAt: '',
          status: '',
          checked: false,
        }),
      );
      commit(
        agentMutationsTypes.setCurrentConversationInConversations(
          <Conversation>{},
        ),
      );

      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async transferConversationToSegment(
    { commit, rootState, state },
    { payload: segmentId },
  ): Promise<any> {
    const agentId = rootState.agent!.agent._id;
    const conversationId = rootState.conversation!.currentConversation._id;
    const url = `${BASE_URL_ORCHESTATOR}/agent/${agentId}/conversation/transferToSegment/${conversationId}`;

    try {
      const response = await Axios.post(url, { toTransferSegment: segmentId });

      commit(
        agentMutationsTypes.removeConversationOpen(
          state.currentConversation._id,
        ),
      );
      commit(
        conversationMutationsTypes.setCurrentConversation(<Conversation>{}),
      );
      commit(
        agentMutationsTypes.setCurrentConversationInConversations(
          <Conversation>{},
        ),
      );

      return response;
    } catch (error) {
      console.log(error);
    }
  },
  changeConversationStatus({ commit, state }, { payload: status }) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${BASE_URL_ORCHESTATOR}/conversation/changeStatus/${state.currentConversation._id}`,
        {
          status: status.name,
        },
      )
        .then(response => {
          if (response) {
            commit(
              conversationMutationsTypes.setCurrentConversation(
                response.data.conversation,
              ),
            );
            resolve(true);
          } else {
            reject({ message: 'Ocurrio un error, no hay response' });
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /**
   * Este metodo se cominica con el orquestados y  obtiene el historico de la conversacion
   * @param param0
   * @param param1
   */
  async grebConversation({ commit, rootState }, { payload }) {
    try {
      // current agent queue
      const agentQueue = rootState.agent!.agentQueueTotal;
      commit(conversationMutationsTypes.setLoading(true));
      const response = await Axios(
        `${BASE_URL_ORCHESTATOR}/agent/${
          rootState.agent!.agent._id
        }/conversation/grab/${payload._id}`,
      );
      const { conversation } = response.data;
      if (payload.status === 'IN_QUEUE' || payload.status === 'AUTO') {
        commit(agentMutationsTypes.setQueueTotal(agentQueue - 1));
      }
      commit(agentMutationsTypes.setAgentOpenConversations([conversation]));
      commit(conversationMutationsTypes.setCurrentConversation(conversation));
      return response;
    } catch (error) {
      payload._id = payload.conversation._id;
      commit(agentMutationsTypes.setRemoveEntryOfQueue(payload));
      throw error;
    } finally {
      commit(conversationMutationsTypes.setLoading(false));
    }
  },
  /**
   * Se ejecuta cuando el agente envia un mensaje, se comunica con el orquesatdor para procesar el envio del mensaje
   * @param param0
   * @param param1
   */
  async sendMessage({ commit, state, rootState }, { payload: message }) {
    if (store.state.conversation!.currentConversation.newinteractions >= 0) {
      commit(
        storeTypes.conversation.mutations.setCurrentConversationNewInteractions(
          0,
        ),
      );
    }
    let text = '';
    if (message.text) text = message.text[0];
    if (message.type === MessageType.IMAGE) message.text = ['test'];
    // check if different from undefined and empty string
    if ((text && text.trim() !== '') || message.type !== MessageType.MESSAGE) {
      const response = await Axios.post(
        `${BASE_URL_ORCHESTATOR}/conversation/${
          state.currentConversation._id
        }/agent/${rootState.agent!.agent._id}/response`,
        message,
      );
      const interaction = response.data.interaction;
      const lastInteraction =
        store.state.agent!.interactions[
          store.state.agent!.interactions.length - 1
        ];
      if (lastInteraction._id !== interaction._id) {
        commit(agentMutationsTypes.setNewInteraction(interaction));
      }

      return response.status;
    }

    const scroll = document.getElementById('chat-content-agent') as HTMLElement;
    scroll.scrollTop = scroll.scrollHeight + 100;
  },
  async returnConversation(
    { commit, rootState },
    { payload: conversationToReturn },
  ) {
    try {
      commit(conversationMutationsTypes.setLoading(true));
      const res = await Axios(
        `${BASE_URL_ORCHESTATOR}/agent/${
          rootState.agent!.agent._id
        }/conversation/returnConversation/${conversationToReturn._id}`,
      );
      const { openConversations } = res.data;
      return openConversations[0];
    } catch (err) {
      console.log(err);
    } finally {
      commit(conversationMutationsTypes.setLoading(false));
    }
  },

  /**
   * Se ejecuta cuando el agente comparte un mensaje, se comunica con el orquestador para procesar el envio de varios mensajes
   * @param param0
   * @param param1
   */
  async shareSendMessage({ commit, rootState }, { payload: content }) {
    if (store.state.conversation!.currentConversation.newinteractions > 0) {
      commit(
        storeTypes.conversation.mutations.setCurrentConversationNewInteractions(
          0,
        ),
      );
    }

    const response = await Axios.post(
      `${BASE_URL_ORCHESTATOR}/conversation/${content.id}/agent/${
        rootState.agent!.agent._id
      }/response`,
      content,
    );

    if (content.id === rootState.conversation!.currentConversation._id) {
      const interaction: Interaction = response.data.interaction;
      commit(agentMutationsTypes.setNewInteraction(interaction));
      commit(storeTypes.conversation.mutations.setOutputMessage(content));
      await Promise.resolve(true);
    }

    return response.status;
  },
  changeCurrentConversationContactData(
    { commit, state },
    { payload: contactData },
  ) {
    const currentConversation = _.cloneDeep(state.currentConversation);
    currentConversation.contactData = contactData;
    commit(
      conversationMutationsTypes.setCurrentConversation(currentConversation),
    );
  },
  setCurrentConversations({ commit, rootState }, { payload: content }) {
    commit(conversationMutationsTypes.setCurrentConversation(content));
  },
  setNewInteractions({ commit, rootState }, { payload: content }) {
    commit(conversationMutationsTypes.setNewInteractions(content));
  },
  async closeAllConversationQueue({ commit }) {
    const currentAgent = store.state.agent!.agent.subscriptions[0];
    await Axios.delete(
      `${BASE_URL_MANAGER}/agent/changeStatusQueue/${currentAgent}`,
    );
  },
  async closeAllConversationOpen({ commit }) {
    const currentAgent = store.state.agent!.agent._id;
    await Axios.delete(
      `${BASE_URL_MANAGER}/agent/changeStatusClose/${currentAgent}`,
    );
  },
  async closeAllConversationAuto({ commit }) {
    const currentProject = store.state.user!.project._id;
    await Axios.delete(
      `${BASE_URL_MANAGER}/agent/changeStatusAuto/${currentProject}`,
    );
  },
};

export default actions;
