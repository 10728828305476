import { DefineTypes, DefineMutations } from '@/app/store/store.helper';
import { RootState } from '@/app/store/root.models';
import {
  Conversation,
  ConversationMessage,
  ConversationState,
  ConversationMessageElement,
} from '@/app/store/modules/conversation/conversation.models';
import { agentMutationsTypes } from '../agent/agent.mutations';

export interface AgentMutations {
  setLoading: boolean;
  setConversationReturn: Conversation;
  setError: Object;
  setMessageInConversation: ConversationMessage;
  setCurrentConversation: Conversation;
  setOutputMessage: ConversationMessageElement;
  setCurrentConversationNewInteractions: number;
  setNewInteractions: number;
}

export const conversationMutationsTypes: DefineTypes<AgentMutations> = {
  setLoading: payload => ({ type: 'setLoading', payload }),
  setConversationReturn: payload => ({
    type: 'setConversationReturn',
    payload,
  }),
  setError: payload => ({ type: 'setError', payload }),
  setMessageInConversation: payload => ({
    type: 'setMessageInConversation',
    payload,
  }),
  setCurrentConversation: payload => ({
    type: 'setCurrentConversation',
    payload,
  }),
  setOutputMessage: payload => ({
    type: 'setOutputMessage',
    payload,
  }),
  setCurrentConversationNewInteractions: payload => ({
    type: 'setCurrentConversationNewInteractions',
    payload,
  }),
  setNewInteractions: payload => ({
    type: 'setNewInteractions',
    payload,
  }),
};

const mutations: DefineMutations<AgentMutations, ConversationState, RootState> =
  {
    setError(state, { payload }) {
      state.error = payload;
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setConversationReturn(state, { payload }) {
      state.conversationReturn = payload;
      setTimeout(() => {
        state.conversationReturn = null;
      }, 1500);
    },
    setMessageInConversation(state, { payload: newMessage }) {
      if (state.currentConversation._id !== newMessage.conversation) {
        return;
      } else {
        this.commit(
          conversationMutationsTypes.setCurrentConversationNewInteractions(
            (newMessage as any).newinteractions,
          ),
        );
      }

      const interactions = state.currentConversation.interactions;
      if (Array.isArray(interactions)) {
        const lastInteraction = interactions[interactions.length - 1];
        if (lastInteraction.output.length) {
          interactions.push({
            conversation: newMessage.conversation,
            input: newMessage.input,
            output: [],
          });
        } else {
          lastInteraction.input = newMessage.input;
        }
      }
    },
    setCurrentConversation(state, { payload: conversation }) {
      state.currentConversation = conversation;
      if (conversation && conversation._id) {
        this.commit(
          agentMutationsTypes.setCurrentConversationInConversations(
            conversation,
          ),
        );
      }
    },
    setCurrentConversationNewInteractions(state, { payload: newInteractions }) {
      state.currentConversation.newinteractions = newInteractions;
      this.commit(
        agentMutationsTypes.setCurrentConversationInConversations(
          state.currentConversation,
        ),
      );
    },
    setOutputMessage(state, { payload: message }) {
      const interactions = state.currentConversation.interactions;
      if (Array.isArray(interactions)) {
        const lastInteraction = interactions[interactions.length - 1];
        if (lastInteraction && Array.isArray(lastInteraction.output)) {
          state.currentConversation.interactions =
            state.currentConversation.interactions.map((element, index) => {
              if (index >= interactions.length - 1) {
                return {
                  ...element,
                  output: element.output.concat(message),
                };
              }
              return element;
            });
        }
      }
    },
    setNewInteractions(state, { payload: newInteractions }) {
      state.currentConversation.newinteractions = newInteractions;
    },
  };

export default mutations;
