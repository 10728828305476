import { Module } from 'vuex';
import { RootState } from '@/app/store/root.models';
import { FilterState, filterInitialState } from './filters.models';
import mutations, { filterMutationsTypes } from './filters.mutations';
import actions, { filtersActionsTypes } from './filters.actions';

export const filters: Module<FilterState, RootState> = {
  namespaced: false,
  state: filterInitialState,
  mutations: mutations,
  actions: actions,
};

export const filtersTypes = {
  mutations: filterMutationsTypes,
  actions: filtersActionsTypes,
};
