import Vue from 'vue';
import App from './app/App.vue';
import Axios, { AxiosError } from 'axios';
import router from './app/router';
import { EmojiPickerPlugin } from 'vue-emoji-picker';

import { store, storeTypes } from './app/store';
import { BASE_URL_MANAGER, BASE_URL_ORCHESTATOR } from '@/config';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import { MazPhoneNumberInput } from 'maz-ui';
import JsonExcel from 'vue-json-excel';
Vue.component('downloadExcel', JsonExcel);
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';
import Toast from 'vue-toastification';

import './register-service-worker';
import 'jquery/dist/jquery.min';
import 'popper.js/dist/umd/popper.min';
import 'bootstrap/dist/js/bootstrap.min';
// Plugins
import '@/assets/plugins/ant-design';
import '@/assets/plugins/font-awesome';
import '@/assets/plugins/leader-line';
import '@/assets/plugins/vue-audio-recorder';
import '@/assets/plugins/vue2-collapse';
import jquery from 'jquery';
window.$ = jquery;

import './assets/css/font-awesome.min.css';
import './assets/fontawesome/css/fontawesome.min.css';
import './assets/fontawesome/css/all.min.css';
import './assets/css/feathericon.min.css';
import './assets/css/bootstrap.min.css';
import './assets/css/bootstrap.bundle.min.js';
import './assets/css/vue2-collapse.css';

//import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

//Vue.use(PerfectScrollbar)Could not find a declaration file for module 'lottie-web-vue'. 'c:/Users/USUARIO/Documents/Dropi/wizard-dashboard/node_modules/lottie-web-vue/dist/lottie-web-vue.umd.js' implicitly has an 'any' type

const VueChatScroll = require('vue-chat-scroll');

Vue.use(VueChatScroll);
Vue.use(MazPhoneNumberInput);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(BootstrapVueIcons);
Vue.use(EmojiPickerPlugin);
// Vue.use(LottieAnimation);
Vue.prototype.$http = Axios;
Vue.prototype.$base = BASE_URL_MANAGER;
Vue.prototype.$baseOrchestator = BASE_URL_ORCHESTATOR;
Vue.prototype.$storeTypes = storeTypes;
Vue.prototype.$dropiAxios = Axios;
Vue.config.productionTip = false;

const toastOptions = {
  // You can set your default options here
};

Vue.use(Toast, toastOptions);
const eventBus = new Vue();
export default eventBus;

Axios.interceptors.response.use(
  response => response,
  async (error: AxiosError) => {
    if (
      error.isAxiosError &&
      error.response!.status === 401 &&
      error.response!.data!.message === 'invalid token'
    ) {
      await store.dispatch(storeTypes.auth.actions.logout());
    }
    return Promise.reject(error);
  },
);

const token = localStorage.getItem('token:wizard');

if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] =
    'Bearer ' + token;
}

export const InstanceVue = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
