import { Module } from 'vuex';
import { RootState } from '@/app/store/root.models';
import { AuthState, initialAuthState } from './auth.models';
import mutations, { authMutationsTypes } from './auth.mutations';
import actions, { authActionsTypes } from './auth.actions';
import getters, { authGettersTypes } from './auth.getter';

export const auth: Module<AuthState, RootState> = {
  namespaced: false,
  state: initialAuthState,
  mutations,
  actions,
  getters,
};

export const authTypes = {
  mutations: authMutationsTypes,
  actions: authActionsTypes,
  getters: authGettersTypes,
};
