export const environment = {
  vueAppAPIOrchestrator: 'https://orchestator.chatcenter.app',
  vueAppAPI: 'https://management.chatcenter.app',
  whatsappCloud: 'https://wp-cloud.dropi.co',
  apiValidaciones: 'https://api.dropi.co/validaciones',
  // dropi api urls
  dropiAPI_CO: 'https://api.dropi.co/integrations', // Colombia
  dropiAPI_EC: 'https://api.dropi.ec/integrations', // Ecuador
  dropiAPI_PA: 'https://api.dropi.pa/integrations', // Panama
  dropiAPI_MX: 'https://api.dropi.mx/integrations', // Mexico
  dropiAPI_PE: 'https://api.dropi.pe/integrations', // Peru
  dropiAPI_CL: 'https://api.dropi.cl/integrations', // Chile
  dropiAPI_ES: 'https://api.dropi.com.es/integrations', // España
  dropiAPI_PY: 'https://api.dropi.com.py/integrations', // Paraguay
};

export const FacebookEnvironment = {
  appId: '552344496853354',
  secret: 'c742036d77010a0067347f98b432b7e8',
};
