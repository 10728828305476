export interface AdminState {
  project: Project;
  agents: Agent[];
  usernames: string[];
}

export interface Project {
  _id: string;
  country: string;
  bussinesStatusList: BussinesStatusList[];
  dropiUsers: DropiUser[];
  name: string;
  bussinnes_account_id: string;
  phone_number_id: string;
  accessToken: string;
}

export type ExtendedAdminState = { admin?: AdminState };
export interface BussinesStatusList {
  name: string;
  description: string;
  color: string;
  message: string;
  finish: boolean;
}

export interface DropiUser {
  token: string;
  country: string;
  name: string;
}

export interface Users {
  name: Name;
  role: string;
  deleted: boolean;
  deletedAt: null;
  _id: string;
  company: string;
  username: string;
  password: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  lastLogin?: Date;
}

export interface Name {
  firstName: string;
  lastName: string;
}

export interface Agent {
  _id: string;
  subscriptions: string[];
  projects: string[];
  disable: boolean;
  deletedAt: null;
  user: string;
  company: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  usrage: Usrage[];
}

export interface Usrage {
  _id: string;
  role: string;
  deleted: boolean;
  deletedAt: null;
  company: string;
  name: Name;
  username: string;
  password: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  lastLogin: Date;
}

export interface Name {
  firstName: string;
  lastName: string;
}

export const initialAdminState: AdminState = {
  project: {
    _id: '',
    country: '',
    bussinesStatusList: [],
    dropiUsers: [],
    name: '',
    bussinnes_account_id: '',
    phone_number_id: '',
    accessToken: '',
  },
  agents: [],
  usernames: [],
};
