import { GetterTree } from 'vuex';
import { RootState } from '@/app/store/root.models';
import { UserState } from './user.models';
import { use } from 'vue/types/umd';

export const userGettersTypes = {
  isLoggedIn: 'isLoggedIn',
  isRedirect: 'isRedirect',
  project: 'project',
  user: 'user',
} as const;

const getters: GetterTree<UserState, RootState> = {
  [userGettersTypes.isLoggedIn]: state =>
    !!(state.user && state.user._id && state.user.login),
  [userGettersTypes.project]: state => state.project,
  [userGettersTypes.isRedirect]: state => state.redirect,
  [userGettersTypes.user]: state => state.user,
};

export default getters;
