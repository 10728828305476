import { DefineTypes, DefineActions } from '@/app/store/store.helper';
import { RootState } from '@/app/store/root.models';
import { FilterState } from './filters.models';
export interface FiltersActions {
  setFilterAgent: FilterState[];
  setFiltersProject: FilterState[];
}

export const filtersActionsTypes: DefineTypes<FiltersActions> = {
  setFilterAgent: payload => ({ type: 'setFilterAgent', payload }),
  setFiltersProject: payload => ({ type: 'setFiltersProject', payload }),
};

const actions: DefineActions<FiltersActions, FilterState, RootState> = {
  setFilterAgent({ commit }, { payload }) {
    commit('setFilterAgent', payload);
  },
  setFiltersProject({ commit }, { payload }) {
    commit('setFiltersProject', payload);
  },
};

export default actions;
