import { DefineTypes, DefineActions } from '@/app/store/store.helper';
import { RootState } from '@/app/store/root.models';
import { AdminState } from './admin.models';
import { AdminMutationsTypes } from './admin.mutations';
import axios from 'axios';
import { BASE_URL_MANAGER } from '@/config';

export interface AdminActions {
  getProject: string;
  getAgents: string;
  setUsernames: string;
}

export const AdminActionsTypes: DefineTypes<AdminActions> = {
  getProject: payload => ({ type: 'getProject', payload }),
  getAgents: payload => ({ type: 'getAgents', payload }),
  setUsernames: payload => ({ type: 'setUsernames', payload }),
};

const adminActions: DefineActions<AdminActions, AdminState, RootState> = {
  async getProject({ commit, dispatch, rootState }, { payload }) {
    try {
      const url = `${BASE_URL_MANAGER}/project/company/${payload}`;
      const response = await axios.get(url);
      commit(AdminMutationsTypes.setProject(response.data[0].project));
    } catch (error) {
      console.error(error);
    }
  },
  async getAgents({ commit, dispatch, rootState }, { payload }) {
    try {
      const url = `${BASE_URL_MANAGER}/agent/users/relAgents/${payload}`;
      const response = await axios.get(url);
      commit(AdminMutationsTypes.setAgents(response.data.agents));
    } catch (error) {
      console.error(error);
    }
  },
  async setUsernames({ commit, dispatch, rootState }, { payload }) {
    try {
      const url = `${BASE_URL_MANAGER}/user/all/${payload}`;
      const response = await axios.get(url);
      commit(AdminMutationsTypes.setUsernames(response.data.users));
    } catch (error) {
      console.error(error);
    }
  },
};

export default adminActions;
