import { environment, FacebookEnvironment } from '@/environments/environment';

export const BASE_URL_MANAGER = environment.vueAppAPI;
export const BASE_URL_ORCHESTATOR = environment.vueAppAPIOrchestrator;
export const BASE_URL_WHATSAPP_CLOUD = environment.whatsappCloud;
export const BASE_VALIDATION_API = environment.apiValidaciones;
export const FACEBOOK_ENVIRONMENT = FacebookEnvironment;

// dictioanry for the countries and their API urls
export const COUNTRIES = {
  CO: environment.dropiAPI_CO,
  EC: environment.dropiAPI_EC,
  PA: environment.dropiAPI_PA,
  MX: environment.dropiAPI_MX,
  PE: environment.dropiAPI_PE,
  CL: environment.dropiAPI_CL,
  ES: environment.dropiAPI_ES,
  PY: environment.dropiAPI_PY,
};
