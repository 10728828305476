import { DefineTypes, DefineMutations } from '@/app/store/store.helper';
import { AuthState } from './auth.models';

export interface AuthMutations {
  setAuthLoading: AuthState['loading'];
}

export const authMutationsTypes: DefineTypes<AuthMutations> = {
  setAuthLoading: payload => ({ type: 'setAuthLoading', payload }),
};

const mutations: DefineMutations<AuthMutations, AuthState> = {
  setAuthLoading(state, { payload }) {
    state.loading = payload;
  },
};

export default mutations;
