import { Module } from "vuex";
import { RootState } from "@/app/store/root.models";
import { initialUserState, UserState } from "./user.models";
import actions, { userActionsTypes } from "./user.actions";
import getters, { userGettersTypes } from "./user.getters";
import mutations, { userMutationsTypes } from "./user.mutations";

export const user: Module<UserState, RootState> = {
    namespaced: false,
    state: initialUserState,
    getters,
    mutations,
    actions,
};

export const userTypes = {
    mutations: userMutationsTypes,
    getters: userGettersTypes,
    actions: userActionsTypes,
};
