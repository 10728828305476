import { DefineMutations, DefineTypes } from '@/app/store/store.helper';
import { RootState } from '@/app/store/root.models';
import { AdminState, Agent, Project, Usrage } from './admin.models';

export interface AdminMutations {
  setProject: Project;
  setAgents: Agent[];
  setUsernames: Usrage[];
}

export const AdminMutationsTypes: DefineTypes<AdminMutations> = {
  setProject: payload => ({ type: 'setProject', payload }),
  setAgents: payload => ({ type: 'setAgents', payload }),
  setUsernames: payload => ({ type: 'setUsernames', payload }),
};

const adminMutations: DefineMutations<AdminMutations, AdminState, RootState> = {
  setProject(state, { payload }) {
    state.project = { ...payload };
  },
  setAgents(state, { payload }) {
    state.agents = payload;
  },
  setUsernames(state, { payload }) {
    for (const user of payload) {
      state.usernames.push(user.username);
    }
    // state.usernames = payload;
  },
};

export default adminMutations;
