import { Module } from 'vuex';
import { RootState } from '@/app/store/root.models';
import { initialAdminState, AdminState } from './admin.models';
import adminMutations, { AdminMutationsTypes } from './admin.mutations';
import adminActions, { AdminActionsTypes } from './admin.actions';

export const admin: Module<AdminState, RootState> = {
  namespaced: false,
  state: initialAdminState,
  mutations: adminMutations,
  actions: adminActions,
};

export const adminTypes = {
  mutations: AdminMutationsTypes,
  actions: AdminActionsTypes,
};
