import { DefineMutations, DefineTypes } from '@/app/store/store.helper';
import { RootState } from '@/app/store/root.models';
import { AgentState, IAgent } from './agent.models';
import {
  Conversation,
  ConversationMessage,
  ConversationNewEntryQueue,
  Interaction,
} from '@/app/store/modules/conversation/conversation.models';

interface FiltersConfig {
  label: string;
  value: string;
  icon: string;
  config: [
    {
      name_filter: string;
      type_filter: string;
      source: string;
      selectedOption: any;
    },
  ];
}

interface PayloadDinamicConversation {
  status: string;
  distribution_company: string;
  id: string;
  phone: string;
  projectId: string;
}

export interface AgentMutations {
  setAgent: AgentState['agent'];
  setLoadingQueue: AgentState['loadingQueue'];
  setLoadingClose: AgentState['loadingClose'];
  setLoadingOpen: AgentState['loadingOpen'];
  setLoadingAuto: AgentState['loadingAuto'];
  resetAgent: undefined;
  setOpenTotalConversation: undefined;
  setAgentOpenConversations: AgentState['agentOpenConversations'];
  setAgentOpenTotalConversations: AgentState['agentOpenTotalConversations'];
  setAgentCloseConversations: AgentState['agentCloseConversations'];
  setAgenCloseTotalConversations: AgentState['agentCloseTotalConversations'];
  setAgentAutoConversations: AgentState['agentAutoConversations'];
  setAgentAutoTotalConversations: AgentState['agentAutoTotalConversations'];
  setQueue: AgentState['agentQueue'];
  resetQueue: AgentState['agentQueue'];
  resetOpen: AgentState['agentOpenConversations'];
  resetAuto: AgentState['agentAutoConversations'];
  setQueueTotal: AgentState['agentQueueTotal'];
  setNewEntryInQueue: ConversationNewEntryQueue;
  setNewEntryInAuto: ConversationNewEntryQueue;
  setRemoveEntryOfQueue: ConversationNewEntryQueue;
  removeConversationOpen: string;
  removeConversationClosed: string;
  removeConversationQueue: string;
  removeConversationAuto: string;
  setMessageInOpenConversation: ConversationMessage;
  setCurrentConversationInConversations: Conversation;
  reSetAgentCloseConversations: string;
  setInteractions: AgentState['interactions'];
  setNewInteraction: Interaction;
  setUpdateInteraction: Interaction;
  setUpdateNewStateConversation: Interaction;
  setActualPage: number;
  setTotalPages: number;
  loadMoreInteractions: Interaction[];
  setAgentTransferredConversation: { agent: any; conversation: Conversation };
  // sort conversation by date desc or asc
  sortConversation: string;
  sortQueueConversation: string;
  // set order conversation
  sortCloseConversation: string;
  sortAutoConversation: string;
  setOrderQueueConversation: number;
  setOrderCloseConversation: number;
  setOrderOpenConversation: number;
  setOrderAutoConversation: number;
  // set page conversation
  setPageCloseConversation: number;
  setPageOpenConversation: number;
  setPageQueueConversation: number;
  setPageAutoConversation: number;

  resetCloseConversation: undefined;
  // set current tab
  setTabSelected: string;

  // set filtered conversations
  setFilteredConversationsOpen: Conversation[];
  setFilteredConversationsClose: Conversation[];
  setFilteredConversationsQueue: Conversation[];
  setFilteredConversationsAuto: Conversation[];

  // set filtered conversations
  setFilteredConversationsOpenTotal: number;
  setFilteredConversationsCloseTotal: number;
  setFilteredConversationsQueueTotal: number;
  setFilteredConversationsAutoTotal: number;

  filterSelectedOpen: FiltersConfig;
  filterSelectedClose: FiltersConfig;
  filterSelectedQueue: FiltersConfig;
  filterSelectedAuto: FiltersConfig;

  removeConversationOpenFiltered: PayloadDinamicConversation;
  removeConversationClosedFiltered: PayloadDinamicConversation;
  removeConversationQueueFiltered: PayloadDinamicConversation;
  removeConversationAutoFiltered: PayloadDinamicConversation;

  setNewEntryInQueueFiltered: Conversation;
  setNewEntryInAutoFiltered: Conversation;
  setNewEntryInCloseFiltered: Conversation;
  setNewEntryInOpenFiltered: Conversation;

  loadingConversation: boolean;
}

export const agentMutationsTypes: DefineTypes<AgentMutations> = {
  setOpenTotalConversation: () => ({ type: 'setOpenTotalConversation' }),
  // order date desc
  setOrderQueueConversation: payload => ({
    type: 'setOrderQueueConversation',
    payload,
  }),
  setOrderCloseConversation: payload => ({
    type: 'setOrderCloseConversation',
    payload,
  }),
  setOrderOpenConversation: payload => ({
    type: 'setOrderOpenConversation',
    payload,
  }),
  setOrderAutoConversation: payload => ({
    type: 'setOrderAutoConversation',
    payload,
  }),
  // page
  setPageCloseConversation: payload => ({
    type: 'setPageCloseConversation',
    payload,
  }),
  setPageOpenConversation: payload => ({
    type: 'setPageOpenConversation',
    payload,
  }),
  setPageQueueConversation: payload => ({
    type: 'setPageQueueConversation',
    payload,
  }),
  setPageAutoConversation: payload => ({
    type: 'setPageAutoConversation',
    payload,
  }),
  setAgent: payload => ({ type: 'setAgent', payload }),
  setLoadingQueue: payload => ({ type: 'setLoadingQueue', payload }),
  setLoadingClose: payload => ({ type: 'setLoadingClose', payload }),
  setLoadingOpen: payload => ({ type: 'setLoadingOpen', payload }),
  setLoadingAuto: payload => ({ type: 'setLoadingAuto', payload }),

  resetAgent: () => ({ type: 'resetAgent' }),
  loadMoreInteractions: payload => ({
    type: 'loadMoreInteractions',
    payload,
  }),
  setActualPage: payload => ({ type: 'setActualPage', payload }),
  setTotalPages: payload => ({ type: 'setTotalPages', payload }),
  setAgentTransferredConversation: payload => ({
    type: 'setAgentTransferredConversation',
    payload,
  }),
  setAgentOpenConversations: payload => ({
    type: 'setAgentOpenConversations',
    payload,
  }),
  setAgentOpenTotalConversations: payload => ({
    type: 'setAgentOpenTotalConversations',
    payload,
  }),
  setAgentCloseConversations: payload => ({
    type: 'setAgentCloseConversations',
    payload,
  }),
  setAgentAutoConversations: payload => ({
    type: 'setAgentAutoConversations',
    payload,
  }),
  setAgentAutoTotalConversations: payload => ({
    type: 'setAgentAutoTotalConversations',
    payload,
  }),
  setAgenCloseTotalConversations: payload => ({
    type: 'setAgenCloseTotalConversations',
    payload,
  }),

  setQueue: payload => ({
    type: 'setQueue',
    payload,
  }),
  resetQueue: payload => ({
    type: 'resetQueue',
    payload,
  }),
  resetOpen: payload => ({
    type: 'resetOpen',
    payload,
  }),
  resetAuto: payload => ({
    type: 'resetAuto',
    payload,
  }),
  setQueueTotal: payload => ({
    type: 'setQueueTotal',
    payload,
  }),
  setNewEntryInQueue: payload => ({
    type: 'setNewEntryInQueue',
    payload,
  }),
  setNewEntryInAuto: payload => ({
    type: 'setNewEntryInAuto',
    payload,
  }),
  setRemoveEntryOfQueue: payload => ({
    type: 'setRemoveEntryOfQueue',
    payload,
  }),
  setMessageInOpenConversation: payload => ({
    type: 'setMessageInOpenConversation',
    payload,
  }),
  setCurrentConversationInConversations: payload => ({
    type: 'setCurrentConversationInConversations',
    payload,
  }),
  removeConversationOpen: payload => ({
    type: 'removeConversationOpen',
    payload,
  }),
  removeConversationClosed: payload => ({
    type: 'removeConversationOpen',
    payload,
  }),
  removeConversationQueue: payload => ({
    type: 'removeConversationQueue',
    payload,
  }),
  removeConversationAuto: payload => ({
    type: 'removeConversationAuto',
    payload,
  }),
  reSetAgentCloseConversations: payload => ({
    type: 'reSetAgentCloseConversations',
    payload,
  }),
  setInteractions: payload => ({
    type: 'setInteractions',
    payload,
  }),
  setNewInteraction: payload => ({
    type: 'setNewInteraction',
    payload,
  }),

  setUpdateInteraction: payload => ({
    type: 'setUpdateInteraction',
    payload,
  }),
  setUpdateNewStateConversation: payload => ({
    type: 'setUpdateNewStateConversation',
    payload,
  }),
  // desc or asc
  sortConversation: payload => ({
    type: 'sortConversation',
    payload,
  }),
  sortQueueConversation: payload => ({
    type: 'sortQueueConversation',
    payload,
  }),
  sortCloseConversation: payload => ({
    type: 'sortCloseConversation',
    payload,
  }),

  sortAutoConversation: payload => ({
    type: 'sortAutoConversation',
    payload,
  }),
  resetCloseConversation: () => ({ type: 'resetCloseConversation' }),

  // set current tab
  setTabSelected: payload => ({ type: 'setTabSelected', payload }),

  // set filtered conversations
  setFilteredConversationsOpenTotal: payload => ({
    type: 'setFilteredConversationsOpenTotal',
    payload,
  }),
  setFilteredConversationsCloseTotal: payload => ({
    type: 'setFilteredConversationsCloseTotal',
    payload,
  }),
  setFilteredConversationsQueueTotal: payload => ({
    type: 'setFilteredConversationsQueueTotal',
    payload,
  }),
  setFilteredConversationsAutoTotal: payload => ({
    type: 'setFilteredConversationsAutoTotal',
    payload,
  }),
  setFilteredConversationsOpen: payload => ({
    type: 'setFilteredConversationsOpen',
    payload,
  }),
  setFilteredConversationsClose: payload => ({
    type: 'setFilteredConversationsClose',
    payload,
  }),
  setFilteredConversationsQueue: payload => ({
    type: 'setFilteredConversationsQueue',
    payload,
  }),
  setFilteredConversationsAuto: payload => ({
    type: 'setFilteredConversationsAuto',
    payload,
  }),

  filterSelectedOpen: payload => ({
    type: 'filterSelectedOpen',
    payload,
  }),
  filterSelectedClose: payload => ({
    type: 'filterSelectedClose',
    payload,
  }),
  filterSelectedQueue: payload => ({
    type: 'filterSelectedQueue',
    payload,
  }),
  filterSelectedAuto: payload => ({
    type: 'filterSelectedAuto',
    payload,
  }),
  removeConversationQueueFiltered: payload => ({
    type: 'removeConversationQueueFiltered',
    payload,
  }),
  removeConversationOpenFiltered: payload => ({
    type: 'removeConversationOpenFiltered',
    payload,
  }),
  removeConversationClosedFiltered: payload => ({
    type: 'removeConversationClosedFiltered',
    payload,
  }),
  removeConversationAutoFiltered: payload => ({
    type: 'removeConversationAutoFiltered',
    payload,
  }),

  setNewEntryInQueueFiltered: payload => ({
    type: 'setNewEntryInQueueFiltered',
    payload,
  }),
  setNewEntryInAutoFiltered: payload => ({
    type: 'setNewEntryInAutoFiltered',
    payload,
  }),
  setNewEntryInCloseFiltered: payload => ({
    type: 'setNewEntryInCloseFiltered',
    payload,
  }),
  setNewEntryInOpenFiltered: payload => ({
    type: 'setNewEntryInOpenFiltered',
    payload,
  }),

  loadingConversation: payload => ({
    type: 'loadingConversation',
    payload,
  }),
};

const mutations: DefineMutations<AgentMutations, AgentState, RootState> = {
  setOpenTotalConversation(state) {
    state.agentOpenTotalConversations--;
  },

  setOrderQueueConversation(state, { payload }) {
    state.orderQueue = payload;
  },
  setOrderCloseConversation(state, { payload }) {
    state.orderClose = payload;
  },
  setOrderOpenConversation(state, { payload }) {
    state.orderOpen = payload;
  },
  setOrderAutoConversation(state, { payload }) {
    state.orderAuto = payload;
  },
  // page
  setPageCloseConversation(state, { payload }) {
    if (payload < 0) payload = 0;
    state.pageClose = payload;
  },
  setPageOpenConversation(state, { payload }) {
    if (payload < 0) payload = 0;
    state.pageOpen = payload;
  },
  setPageQueueConversation(state, { payload }) {
    if (payload < 0) payload = 0;
    state.pageQueue = payload;
  },
  setPageAutoConversation(state, { payload }) {
    if (payload < 0) payload = 0;
    state.pageAuto = payload;
  },
  setAgent(state, { payload }) {
    state.agent = payload;
  },
  setLoadingQueue(state, { payload }) {
    state.loadingQueue = payload;
  },
  setLoadingClose(state, { payload }) {
    state.loadingClose = payload;
  },
  setLoadingOpen(state, { payload }) {
    state.loadingOpen = payload;
  },
  setLoadingAuto(state, { payload }) {
    state.loadingAuto = payload;
  },
  setAgentTransferredConversation(state, { payload: transferred }) {
    state.transferredToAgent = transferred;
    setTimeout(() => (state.transferredToAgent = null), 1000);
  },
  resetAgent(state) {
    state.agent = <IAgent>{};
    state.agentSockets = [];
    state.agentQueue = [];
    state.agentOpenConversations = [];
    state.agentCloseConversations = [];
  },
  setAgentOpenConversations(state, { payload: conversations }) {
    const newConversations = conversations.map(conversation => {
      const { currentInputInteraction } = conversation;
      if (Array.isArray(currentInputInteraction)) {
        conversation.newMessage = conversation.currentInputInteraction.length;
        conversation.currentInputInteraction = [];
        conversation.checked = false;
      }
      return { ...conversation, isClosed: false };
    });
    state.agentOpenConversations.push(...newConversations);

    // Eliminar conversaciones duplicadas
    const unique = state.agentOpenConversations.filter(
      (v, i, a) => a.findIndex(t => t._id === v._id) === i,
    );
    state.agentOpenConversations = unique;

    // Ordenar las conversaciones por fecha de actualización en orden descendente
    state.agentOpenConversations = state.agentOpenConversations.sort((a, b) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    });

    // Eliminar las conversaciones de state.agentCloseConversations si también están en state.agentOpenConversations
    state.agentCloseConversations = state.agentCloseConversations.filter(
      conversation => {
        const index = state.agentOpenConversations.findIndex(
          c => c._id === conversation._id,
        );
        return index === -1;
      },
    );
    state.agentQueue = state.agentQueue.filter(conversation => {
      const index = state.agentOpenConversations.findIndex(
        c => c._id === conversation.conversation._id,
      );
      return index === -1;
    });
    // if (state.agentQueueTotal > 0) state.agentQueueTotal--;
  },
  setAgentOpenTotalConversations(state, { payload: total }) {
    state.agentOpenTotalConversations = total;
  },

  setAgentCloseConversations(state, { payload: conversations }) {
    // if (conversations[0].status === "IN_QUEUE") state.agentQueueTotal--;
    if (conversations.length < 1) return;
    const orderClose = state.orderClose;
    const newConversations = conversations.map(conversation => {
      const { currentInputInteraction } = conversation;
      if (Array.isArray(currentInputInteraction)) {
        conversation.newMessage = conversation.currentInputInteraction.length;
        conversation.currentInputInteraction = [];
        conversation.newinteractions = 0;
      }
      return { ...conversation, isClosed: true, status: 'RESOLVED' };
    });

    state.agentCloseConversations.push(...newConversations);

    // Sort agentCloseConversations based on orderClose
    state.agentCloseConversations.sort((a, b) => {
      const dateA = new Date(a.updatedAt).getTime();
      const dateB = new Date(b.updatedAt).getTime();
      return orderClose === 1 ? dateB - dateA : dateA - dateB;
    });

    // Remove from agentQueue if exists
    state.agentQueue = state.agentQueue.filter(
      queueConversation =>
        queueConversation.consumer !== conversations[0].currentConsumer,
    );
  },
  setAgentAutoConversations(state, { payload: conversations }) {
    const orderAuto = state.orderAuto;
    // if exist in close conversation remove it and add to auto conversation
    const closeConversation = state.agentCloseConversations;
    closeConversation.map(closeConversation => {
      if (closeConversation.currentConsumer == conversations[0].consumer) {
        state.agentCloseConversations.splice(
          state.agentCloseConversations.indexOf(closeConversation),
          1,
        );
      }
    });
    // if exist in open or queue conversation do nothing
    const openConversation = state.agentOpenConversations;
    conversations.map(conversation => {
      // search in open conversation by consumer
      const index = openConversation.findIndex(
        c => c.currentConsumer === conversation.consumer,
      );
      // if no exist in open conversation add to auto conversation
      if (index === -1) {
        state.agentAutoConversations.push(conversation);
      }

      // search in queue conversation by consumer
      const indexQueue = state.agentQueue.findIndex(
        c => c.consumer === conversation.consumer,
      );
      // if no exist in queue conversation add to auto conversation
      if (indexQueue === -1) {
        state.agentAutoConversations.push(conversation);
      }
    });
    state.agentAutoConversations.sort((a, b) => {
      const dateA = new Date(a.start_time).getTime();
      const dateB = new Date(b.start_time).getTime();
      return orderAuto === 1 ? dateB - dateA : dateA - dateB;
    });
  },
  setAgenCloseTotalConversations(state, { payload }) {
    state.agentCloseTotalConversations = payload;
  },

  reSetAgentCloseConversations(state: any) {
    state.agentCloseConversations = [];
    state.agentOpenConversations = [];
  },
  setAgentAutoTotalConversations(state, { payload }) {
    state.agentAutoTotalConversations = payload;
  },

  setQueue(state, { payload }) {
    const orderQueue = state.orderQueue;
    // create a new set of unique conversation IDs
    const uniqueIds = new Set(
      state.agentQueue.map(conversation => conversation._id),
    );

    // filter out conversations that are already in the queue by checking if their ID is in the unique set
    const newConversations = payload.filter(
      conversation => !uniqueIds.has(conversation._id),
    );

    // add new conversations to the end of the queue
    state.agentQueue.push(...newConversations);

    // Sort agentQueue based on orderQueue
    state.agentQueue.sort((a, b) => {
      const dateA = new Date(a.start_time).getTime();
      const dateB = new Date(b.start_time).getTime();
      return orderQueue === 1 ? dateA - dateB : dateB - dateA;
    });
  },
  resetQueue(state, { payload }) {
    state.agentQueue = payload;
  },
  resetOpen(state, { payload }) {
    state.agentOpenConversations = payload;
  },
  resetAuto(state, { payload }) {
    state.agentAutoConversations = payload;
  },
  setQueueTotal(state, { payload }) {
    state.agentQueueTotal = payload;
  },
  setNewEntryInQueue(state, { payload: item }) {
    const {
      agentQueue,
      agentAutoConversations,
      agentCloseConversations,
      orderQueue,
    } = state;

    const existInQueue = agentQueue.find(q => q.consumer === item.consumer);
    if (!existInQueue) {
      state.agentQueue.push(item);
      state.agentQueueTotal++;
    }

    state.agentAutoConversations = agentAutoConversations.filter(
      conversation => conversation.consumer !== item.consumer,
    );

    const indexAuto = state.agentAutoConversations.findIndex(
      c => c.consumer === item.consumer,
    );
    if (indexAuto !== -1) {
      state.agentAutoConversations.splice(indexAuto, 1);
    }

    // Sort agentQueue based on orderQueue
    state.agentQueue.sort((a, b) => {
      const dateA = new Date(a.start_time).getTime();
      const dateB = new Date(b.start_time).getTime();
      return orderQueue === 1 ? dateA - dateB : dateB - dateA;
    });

    // Remove from agentCloseConversations if exists
    state.agentCloseConversations = agentCloseConversations.filter(
      closeConversation => closeConversation.currentConsumer !== item.consumer,
    );
    // remove from agentAutoConversations if exists
    state.agentAutoConversations = agentAutoConversations.filter(
      autoConversation => autoConversation.consumer[0] !== item.consumer,
    );
  },
  setNewEntryInAuto(state, { payload: item }) {
    const closeConversation = state.agentCloseConversations;
    const openConversation = state.agentOpenConversations;
    const indexQueue = state.agentQueue.findIndex(
      c => c.consumer === item.consumer[0],
    );
    const index = openConversation.findIndex(
      c => c.currentConsumer === item.consumer[0],
    );
    const indexClose = closeConversation.findIndex(
      c => c.currentConsumer === item.consumer[0],
    );

    if (index === -1 && indexQueue === -1 && indexClose === -1) {
      state.agentAutoConversations.push(item);
    }
    if (indexClose !== -1) {
      state.agentAutoConversations.push(item);
      state.agentCloseConversations.splice(indexClose, 1);
    }

    const orderAuto = state.orderAuto;
    // if -1 is oldest
    state.agentAutoConversations.sort((a, b) => {
      const dateA = new Date(a.start_time).getTime();
      const dateB = new Date(b.start_time).getTime();
      return orderAuto === 1 ? dateA - dateB : dateB - dateA;
    });
  },

  setRemoveEntryOfQueue(state, { payload: item }) {
    if (!item._id) return;

    const removeFromArray = (array: ConversationNewEntryQueue[]) => {
      const index = array.findIndex(
        ({ conversation }) => conversation && conversation._id === item._id,
      );
      if (index !== -1) {
        array.splice(index, 1);
        return true;
      }
      return false;
    };

    if (removeFromArray(state.agentQueue)) {
      state.agentQueueTotal--;
    }

    removeFromArray(state.agentAutoConversations);
  },
  setMessageInOpenConversation(state, { payload: newMessage }) {
    state.agentOpenConversations = state.agentOpenConversations.map(
      conversation => {
        if (conversation._id !== newMessage.conversation) return conversation;
        const interactions = conversation.interactions;
        if (Array.isArray(interactions)) {
          const lastInteraction = interactions[interactions.length - 1];
          if (lastInteraction.output.length) {
            interactions.push({
              conversation: newMessage.conversation,
              input: newMessage.input,
              output: [],
            });
          } else {
            lastInteraction.input = newMessage.input;
          }
        }
        const newMessageJson = newMessage.input as any;
        conversation.conversationJson =
          newMessageJson.conversation.conversationJson;
        return {
          ...conversation,
          newMessage: !conversation.newMessage
            ? 1
            : conversation.newMessage + 1,
          newinteractions: (newMessage as any).newinteractions,
          updatedAt: (newMessage as any).input.createdAt,
          interactions,
        };
      },
    );
    // new message on top of the list
    state.agentOpenConversations = state.agentOpenConversations.sort((a, b) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    });
  },
  setCurrentConversationInConversations(state, { payload: conversation }) {
    if (conversation && conversation._id) {
      state.agentOpenConversations = state.agentOpenConversations.map(
        agentConversation => {
          if (agentConversation._id === conversation._id) {
            return {
              ...conversation,
            };
          } else {
            return agentConversation;
          }
        },
      );

      state.agentCloseConversations = state.agentCloseConversations.map(
        agentConversation => {
          if (agentConversation._id === conversation._id) {
            return {
              ...conversation,
            };
          } else {
            return agentConversation;
          }
        },
      );
    }
  },

  removeConversationOpen(state, { payload: id }) {
    state.agentOpenConversations = state.agentOpenConversations.filter(
      conversation => conversation._id !== id,
    );
  },
  removeConversationClosed(state, { payload: id }) {
    state.agentCloseConversations = state.agentCloseConversations.filter(
      conversation => conversation._id !== id,
    );
  },
  removeConversationQueue(state, { payload: id }) {
    state.agentQueue = state.agentQueue.filter(
      conversation => conversation.consumer !== id,
    );
  },
  removeConversationAuto(state, { payload: id }) {
    state.agentAutoConversations = state.agentAutoConversations.filter(
      conversation => conversation._id !== id,
    );
  },
  setInteractions(state, { payload: interactions }) {
    state.interactions = interactions;
  },
  setNewInteraction(state, { payload: interaction }) {
    let tempInteraction: any = interaction;
    let temp: any = {
      conversation: tempInteraction.conversation._id,
      input: interaction,
      newinteractions: tempInteraction.conversation.newinteractions,
    };

    state.interactions.push(interaction);
    state.agentOpenConversations = state.agentOpenConversations.map(
      conversation => {
        if (conversation._id !== temp.conversation) return conversation;
        const interactions = conversation.interactions;
        if (Array.isArray(interactions)) {
          const lastInteraction = interactions[interactions.length - 1];
          if (lastInteraction.output.length) {
            interactions.push({
              conversation: temp.conversation,
              input: temp.input,
              output: [],
            });
          } else {
            lastInteraction.input = temp.input;
          }
        }
        conversation.conversationJson =
          tempInteraction.conversation.conversationJson;
        return {
          ...conversation,
          newMessage: !conversation.newMessage
            ? 1
            : conversation.newMessage + 1,
          newinteractions: temp.newinteractions,
          updatedAt: temp.input.createdAt,
          interactions,
        };
      },
    );

    // new message on top of the list
    state.agentOpenConversations = state.agentOpenConversations.sort((a, b) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    });
    setTimeout(() => {
      const scroll = document.getElementById(
        'chat-content-agent',
      ) as HTMLElement;
      scroll.scrollTop = scroll.scrollHeight;
    }, 500);
  },
  setActualPage(state, { payload: actualPage }) {
    state.actualPage = actualPage;
  },
  setTotalPages(state, { payload: totalPages }) {
    state.totalPages = totalPages;
  },
  loadMoreInteractions(state, { payload: interactions }) {
    state.interactions = [...interactions, ...state.interactions];
  },
  setUpdateInteraction(state, { payload: interaction }) {
    if (state.interactions.length > 0) {
      state.interactions = state.interactions.map(inter => {
        if (inter._id === interaction._id) {
          return interaction;
        } else {
          return inter;
        }
      });
    }
  },
  setUpdateNewStateConversation(state, { payload: interaction }) {
    //change the new state of the conversation
    let index = state.agentOpenConversations.findIndex(
      e => e._id == interaction.conversation,
    );
    if (index > -1) {
      state.agentOpenConversations[index].newinteractions = 0;
    }
  },
  sortConversation(state, handler) {
    const sortingOrder = handler.payload === 'oldest' ? 1 : -1;
    const isFilterActive = state.filterSelectedOpen.value !== 'all';

    if (isFilterActive) {
      state.agentOpenConversationsFiltered =
        state.agentOpenConversationsFiltered.sort((a, b) => {
          return (
            sortingOrder *
            (new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())
          );
        });
    } else {
      state.agentOpenConversations = state.agentOpenConversations.sort(
        (a, b) => {
          return (
            sortingOrder *
            (new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())
          );
        },
      );
    }
  },
  sortQueueConversation(state, handler) {
    const sortingOrder = handler.payload === 'oldest' ? 1 : -1;
    const isFilterActive = state.filterSelectedQueue.value !== 'all';
    if (isFilterActive) {
      state.agentQueueFiltered = state.agentQueueFiltered.sort((a, b) => {
        return (
          sortingOrder *
          (new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())
        );
      });
    } else {
      state.agentQueue = state.agentQueue.sort((a, b) => {
        return (
          sortingOrder *
          (new Date(a.start_time).getTime() - new Date(b.start_time).getTime())
        );
      });
    }
  },

  sortCloseConversation(state, handler) {
    const sortingOrder = handler.payload === 'oldest' ? 1 : -1;
    const isFilterActive = state.filterSelectedClose.value !== 'all';
    if (isFilterActive) {
      state.agentCloseConversationsFiltered =
        state.agentCloseConversationsFiltered.sort((a, b) => {
          return (
            sortingOrder *
            (new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())
          );
        });
    } else {
      state.agentCloseConversations = state.agentCloseConversations.sort(
        (a, b) => {
          return (
            sortingOrder *
            (new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())
          );
        },
      );
    }
  },

  sortAutoConversation(state, handler) {
    const sortingOrder = handler.payload === 'oldest' ? 1 : -1;
    const isFilterActive = state.filterSelectedAuto.value !== 'all';
    if (isFilterActive) {
      state.agentAutoConversationsFiltered =
        state.agentAutoConversationsFiltered.sort((a, b) => {
          return (
            sortingOrder *
            (new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())
          );
        });
    } else {
      state.agentAutoConversations = state.agentAutoConversations.sort(
        (a, b) => {
          return (
            sortingOrder *
            (new Date(a.start_time).getTime() -
              new Date(b.start_time).getTime())
          );
        },
      );
    }
  },

  resetCloseConversation(state) {
    state.agentCloseConversations = [];
  },

  setTabSelected(state, { payload: tabSelected }) {
    state.tabSelected = tabSelected;
  },

  setFilteredConversationsOpen(state, { payload: filteredConversationsOpen }) {
    state.agentOpenConversationsFiltered = filteredConversationsOpen;
    // sort by date
    state.agentOpenConversationsFiltered =
      state.agentOpenConversationsFiltered.sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });
  },
  setFilteredConversationsClose(
    state,
    { payload: filteredConversationsClosed },
  ) {
    state.agentCloseConversationsFiltered = filteredConversationsClosed;
    // sort by date
    state.agentCloseConversationsFiltered =
      state.agentCloseConversationsFiltered.sort((a, b) => {
        return (
          new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
        );
      });
  },
  setFilteredConversationsQueue(
    state,
    { payload: filteredConversationsQueue },
  ) {
    state.agentQueueFiltered = filteredConversationsQueue;
    // sort by date
    state.agentQueueFiltered = state.agentQueueFiltered.sort((a, b) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    });
  },
  setFilteredConversationsAuto(state, { payload: filteredConversationsAuto }) {
    state.agentAutoConversationsFiltered = filteredConversationsAuto;
    // sort by date
    state.agentAutoConversationsFiltered =
      state.agentAutoConversationsFiltered.sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });
  },

  setFilteredConversationsOpenTotal(
    state,
    { payload: filteredConversationsOpenTotal },
  ) {
    state.agentOpenTotalConversationsFiltered = filteredConversationsOpenTotal;
  },
  setFilteredConversationsCloseTotal(
    state,
    { payload: filteredConversationsCloseTotal },
  ) {
    state.agentCloseTotalConversationsFiltered =
      filteredConversationsCloseTotal;
  },
  setFilteredConversationsQueueTotal(
    state,
    { payload: filteredConversationsQueueTotal },
  ) {
    state.agentQueueTotalFiltered = filteredConversationsQueueTotal;
  },
  setFilteredConversationsAutoTotal(
    state,
    { payload: filteredConversationsAutoTotal },
  ) {
    state.agentAutoTotalConversationsFiltered = filteredConversationsAutoTotal;
  },

  filterSelectedAuto(state, { payload: filterSelectedAuto }) {
    state.filterSelectedAuto = filterSelectedAuto;
  },
  filterSelectedOpen(state, { payload: filterSelectedOpen }) {
    state.filterSelectedOpen = filterSelectedOpen;
  },
  filterSelectedClose(state, { payload: filterSelectedClose }) {
    state.filterSelectedClose = filterSelectedClose;
  },
  filterSelectedQueue(state, { payload: filterSelectedQueue }) {
    state.filterSelectedQueue = filterSelectedQueue;
  },

  removeConversationQueueFiltered(state, { payload: id }) {
    state.agentQueueFiltered = state.agentQueueFiltered.filter(
      conversation => conversation.currentConsumer !== id.phone,
    );
  },
  removeConversationOpenFiltered(state, { payload: id }) {
    state.agentOpenConversationsFiltered =
      state.agentOpenConversationsFiltered.filter(
        conversation => conversation._id !== id.phone,
      );
  },
  removeConversationClosedFiltered(state, { payload: id }) {
    state.agentCloseConversationsFiltered =
      state.agentCloseConversationsFiltered.filter(
        conversation => conversation._id !== id.phone,
      );
  },
  removeConversationAutoFiltered(state, { payload: id }) {
    state.agentAutoConversationsFiltered =
      state.agentAutoConversationsFiltered.filter(
        conversation => conversation._id !== id.phone,
      );
  },
  setNewEntryInAutoFiltered(state, { payload: item }) {
    const conversations = state.agentAutoConversationsFiltered;
    const index = conversations.findIndex(c => c._id === item._id);
    if (index === -1) {
      state.agentAutoConversationsFiltered.push(item);
    }
    if (state.orderAuto === -1) {
      state.agentAutoConversationsFiltered =
        state.agentAutoConversationsFiltered.sort((a, b) => {
          return (
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
        });
    } else {
      state.agentAutoConversationsFiltered =
        state.agentAutoConversationsFiltered.sort((a, b) => {
          return (
            new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
          );
        });
    }
  },
  setNewEntryInQueueFiltered(state, { payload: item }) {
    const conversations = state.agentQueueFiltered;
    const index = conversations.findIndex(c => c._id === item._id);
    if (index === -1) {
      state.agentQueueFiltered.push(item);
    }
    if (state.orderQueue === -1) {
      state.agentQueueFiltered = state.agentQueueFiltered.sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });
    } else {
      state.agentQueueFiltered = state.agentQueueFiltered.sort((a, b) => {
        return (
          new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
        );
      });
    }
  },
  setNewEntryInCloseFiltered(state, { payload: item }) {
    const conversations = state.agentCloseConversationsFiltered;
    const index = conversations.findIndex(c => c._id === item._id);
    if (index === -1) {
      state.agentCloseConversationsFiltered.push(item);
    }
    if (state.orderClose === -1) {
      state.agentCloseConversationsFiltered =
        state.agentCloseConversationsFiltered.sort((a, b) => {
          return (
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
        });
    } else {
      state.agentCloseConversationsFiltered =
        state.agentCloseConversationsFiltered.sort((a, b) => {
          return (
            new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
          );
        });
    }
  },
  setNewEntryInOpenFiltered(state, { payload: item }) {
    const conversations = state.agentOpenConversationsFiltered;
    const index = conversations.findIndex(c => c._id === item._id);
    if (index === -1) {
      state.agentOpenConversationsFiltered.push(item);
    }
    if (state.orderOpen === -1) {
      state.agentOpenConversationsFiltered =
        state.agentOpenConversationsFiltered.sort((a, b) => {
          return (
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
        });
    } else {
      state.agentOpenConversationsFiltered =
        state.agentOpenConversationsFiltered.sort((a, b) => {
          return (
            new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
          );
        });
    }
  },

  loadingConversation(state, { payload: loadingConversation }) {
    state.loadingConversation = loadingConversation;
  },
};

export default mutations;
