import Vue from 'vue';
import VueRouter from 'vue-router';
import { store } from './../store';
import { auth } from '../store/modules/auth';
// Components
const AppLogin = () => import('../views/app-login/AppLogin.vue');
const AgentChat = () => import('../views/agent-chat/AgentChat.vue');

const AgentsManagement = () =>
  import('../views/administrator/agents-management/AgentsManagement.vue');
const AdminsManagement = () =>
  import('../views/administrator/admins-management/AdminsManagement.vue');
const AdministratorDashboard = () =>
  import(
    '@/app/views/administrator/administrator-dashboard/AdministratorDashboard.vue'
  );
const ProjectsManagement = () =>
  import('../views/administrator/projects-management/ProjectsManagement.vue');
const FlowsManagement = () =>
  import('@/app/views/administrator/flows-management/FlowsManagement.vue');
const ConversationsManagement = () =>
  import(
    '@/app/views/administrator/conversations-management/ConversationsManagement.vue'
  );
const BussinessStates = () =>
  import(
    '@/app/views/administrator/bussiness-states-management/BussinessStatesManagement.vue'
  );
const CompaniesManagement = () =>
  import(
    '@/app/views/administrator/companies-management/CompaniesManagement.vue'
  );

const Ordenes = () => import('../views/orders/Orders.vue');

const Integrations = () =>
  import('../views/administrator/integrations/Integrations.vue');
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: AppLogin,
    /*  redirect: {
        name: "agent"
      }*/
  },
  {
    name: 'login',
    path: '/login',
    component: AppLogin,
  },
  {
    path: '/flow',
    name: 'flow',
    component: FlowsManagement,
  },

  {
    path: '/agent',
    name: 'agent',
    component: AgentChat,
    meta: {
      authorize: ['AGENT'],
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdministratorDashboard,
    meta: {
      authorize: ['ADMIN', 'BASE'],
    },
  },
  {
    path: '/states',
    name: 'states',
    component: BussinessStates,
    meta: {
      authorize: ['ADMIN', 'BASE'],
    },
  },
  {
    path: '/agents',
    name: 'agents',
    component: AgentsManagement,
    meta: {
      authorize: ['ADMIN', 'BASE'],
    },
  },
  {
    path: '/admins',
    name: 'admins',
    component: AdminsManagement,
    meta: {
      authorize: ['ADMIN', 'BASE'],
    },
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectsManagement,
    meta: {
      authorize: ['ADMIN', 'BASE'],
    },
  },
  {
    path: '/companies',
    name: 'companies',
    component: CompaniesManagement,
    meta: {
      authorize: ['ADMIN', 'BASE'],
    },
  },
  {
    path: '/conversations',
    name: 'conversations',
    component: ConversationsManagement,
    meta: {
      authorize: ['ADMIN', 'BASE'],
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/app/views/app-register/AppRegister.vue'),
  },
  {
    path: '/myOrders',
    name: 'myOrders',
    component: Ordenes,
    meta: {
      agent: true,
    },
  },
  {
    path: '/integrations',
    name: 'integrations',
    component: Integrations,
    meta: {
      authorize: ['ADMIN', 'BASE'],
    },
  },
  {
    path: '/loading',
    name: 'loading',
    component: () => import('@/app/views/waiting/waiting.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },
];

function scrollBehavior(to: any, from: any, savedPosition: any) {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { x: 0, y: 0 };
  }
}
const router = new VueRouter({ mode: 'history', routes, scrollBehavior });

router.beforeEach((to, from, next) => {
  const authorize = to.meta!.authorize as string[];
  const user = store.getters['user'].role;
  if (authorize) {
    if (!user) {
      return next({ path: '/login' });
    }
  }
  next();
});
export default router;
