import { Module } from 'vuex';
import { RootState } from '@/app/store/root.models';
import { initialAgentState, AgentState } from './agent.models';
import mutations, { agentMutationsTypes } from './agent.mutations';
import actions, { agentActionsTypes } from './agent.actions';

export const agent: Module<AgentState, RootState> = {
  namespaced: false,
  state: initialAgentState,
  mutations: mutations,
  actions: actions,
};

export const agentTypes = {
  mutations: agentMutationsTypes,
  actions: agentActionsTypes,
};
