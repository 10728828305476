import { Module } from 'vuex';
import { RootState } from '@/app/store/root.models';
import {
  ConversationState,
  initialConversationState,
} from './conversation.models';
import mutations, {
  conversationMutationsTypes,
} from './conversation.mutations';
import actions, { conversationActionsTypes } from './conversation.actions';

export const conversation: Module<ConversationState, RootState> = {
  namespaced: false,
  state: initialConversationState,
  mutations: mutations,
  actions: actions,
};

export const conversationTypes = {
  mutations: conversationMutationsTypes,
  actions: conversationActionsTypes,
};
