import { DefineTypes, DefineActions } from '@/app/store/store.helper';
import { RootState } from '@/app/store/root.models';
import { AgentState, IAgent, SOCKET_EVENTS } from './agent.models';
import { UserState } from '../user/user.models';
import Axios from 'axios';
import { BASE_URL_MANAGER, BASE_URL_ORCHESTATOR } from '@/config';
import { agentMutationsTypes } from './agent.mutations';
import {
  ConversationNewEntryQueue,
  Conversation,
} from '@/app/store/modules/conversation/conversation.models';
import { conversationMutationsTypes } from '../conversation/conversation.mutations';
import { store } from '@/app/store';
import router from '@/app/router';

import { Centrifuge } from 'centrifuge';
interface FiltersConfig {
  label: string;
  value: string;
  icon: string;
  config: [
    {
      name_filter: string;
      type_filter: string;
      source: string;
      selectedOption: any;
    },
  ];
}
export interface AgentActions {
  loadAgent: UserState['user']['_id'];
  loadAgentOpenConversations: {
    agent_id: string;
    page?: number;
    limit?: number;
    sort?: number; // 1 asc, -1 desc
  };
  loadAgentCloseConversations: {
    agent_id: string;
    page?: number;
    sort?: number; // 1 asc, -1 desc
  };
  loadAgentQueues: {
    subscriptions: AgentState['agent']['subscriptions'];
    page?: number;
    sort?: number; // 1 asc, -1 desc
    restartQueue?: boolean;
  };
  loadAgentAutoConversations: {
    subscriptions: AgentState['agent']['subscriptions'];
    page?: number;
    sort?: number; // 1 asc, -1 desc
    limit?: number;
  };

  playSound: string;
  loadActualInteractions: { conversationId: string; page: number };
  updateTotalConversationsOpen: number;
  orderConversations: string;
  orderQueuesConversations: string;
  orderCloseConversations: string;
  orderAutoConversations: string;

  setOrderConversations: number;
  setOrderQueuesConversations: number;
  setOrderCloseConversations: number;
  setOrderAutoConversations: number;

  setPageQueuesConversations: number;
  setPageCloseConversations: number;
  setPageOpenConversations: number;
  setPageAutoConversations: number;

  resetQueueConversations: void;
  resetOpenConversations: void;
  resetCloseConversations: void;
  resetAutoConversations: void;
  // set current tab
  setTabSelected: string;

  // set filtered conversations
  setFilteredConversationsOpen: Conversation[];
  setFilteredQueuesConversations: Conversation[];
  setFilteredCloseConversations: Conversation[];
  setFilteredAutoConversations: Conversation[];

  filterSelectedOpen: FiltersConfig;
  filterSelectedQueues: FiltersConfig;
  filterSelectedClose: FiltersConfig;
  filterSelectedAuto: FiltersConfig;
}
const NEW_MESSAGE_SOUND = 'http://soundbible.com/grab.php?id=1645&type=mp3';
const NEW_CONVERSATION_SOUND =
  'https://www.ringtina.com.ar/Descargar/Ringtones/Notificaciones/Tejat.mp3?_=5';
export const agentActionsTypes: DefineTypes<AgentActions> = {
  loadAgent: payload => ({ type: 'loadAgent', payload }),
  loadAgentOpenConversations: payload => ({
    type: 'loadAgentOpenConversations',
    payload,
  }),
  loadAgentCloseConversations: payload => ({
    type: 'loadAgentCloseConversations',
    payload,
  }),
  loadAgentQueues: payload => ({
    type: 'loadAgentQueues',
    payload,
  }),
  loadAgentAutoConversations: payload => ({
    type: 'loadAgentAutoConversations',
    payload,
  }),
  playSound: payload => ({
    type: 'playSound',
    payload,
  }),
  loadActualInteractions: payload => ({
    type: 'loadActualInteractions',
    payload,
  }),
  orderConversations: payload => ({
    type: 'orderConversations',
    payload,
  }),
  orderQueuesConversations: payload => ({
    type: 'orderQueuesConversations',
    payload,
  }),
  orderCloseConversations: payload => ({
    type: 'orderCloseConversations',
    payload,
  }),
  orderAutoConversations: payload => ({
    type: 'orderAutoConversations',
    payload,
  }),
  setOrderConversations: payload => ({
    type: 'setOrderConversations',
    payload,
  }),
  setOrderQueuesConversations: payload => ({
    type: 'setOrderQueuesConversations',
    payload,
  }),
  setOrderCloseConversations: payload => ({
    type: 'setOrderCloseConversations',
    payload,
  }),
  setOrderAutoConversations: payload => ({
    type: 'setOrderAutoConversations',
    payload,
  }),
  setPageQueuesConversations: payload => ({
    type: 'setPageQueuesConversations',
    payload,
  }),
  setPageCloseConversations: payload => ({
    type: 'setPageCloseConversations',
    payload,
  }),
  setPageOpenConversations: payload => ({
    type: 'setPageOpenConversations',
    payload,
  }),

  setPageAutoConversations: payload => ({
    type: 'setPageAutoConversations',
    payload,
  }),

  updateTotalConversationsOpen: payload => ({
    type: 'updateTotalConversationsOpen',
    payload,
  }),

  // ay queue conversations agent
  resetQueueConversations: payload => ({
    type: 'resetQueueConversations',
    payload,
  }),
  resetOpenConversations: payload => ({
    type: 'resetOpenConversations',
    payload,
  }),
  resetAutoConversations: payload => ({
    type: 'resetAutoConversations',
    payload,
  }),

  // reset close conversations agent
  resetCloseConversations: payload => ({
    type: 'resetCloseConversations',
    payload,
  }),

  // set current tab
  setTabSelected: payload => ({
    type: 'setTabSelected',
    payload,
  }),

  setFilteredConversationsOpen: payload => ({
    type: 'setFilteredConversationsOpen',
    payload,
  }),
  setFilteredQueuesConversations: payload => ({
    type: 'setFilteredQueuesConversations',
    payload,
  }),
  setFilteredCloseConversations: payload => ({
    type: 'setFilteredCloseConversations',
    payload,
  }),
  setFilteredAutoConversations: payload => ({
    type: 'setFilteredAutoConversations',
    payload,
  }),

  filterSelectedOpen: payload => ({
    type: 'filterSelectedOpen',
    payload,
  }),
  filterSelectedQueues: payload => ({
    type: 'filterSelectedQueues',
    payload,
  }),
  filterSelectedClose: payload => ({
    type: 'filterSelectedClose',
    payload,
  }),
  filterSelectedAuto: payload => ({
    type: 'filterSelectedAuto',
    payload,
  }),
};

const actions: DefineActions<AgentActions, AgentState, RootState> = {
  async loadAgent({ commit, dispatch, rootState }, { payload }) {
    const token = localStorage.getItem('token:wizard') || '';

    const centrifuge = new Centrifuge(
      'wss://centrifugo.dropi.co:8000/connection/websocket',
      {
        token,
      },
    );
    centrifuge.connect();
    const url = `${BASE_URL_MANAGER}/user/${payload}/agent`;
    Axios(url).then(async response => {
      const { agent } = <{ agent: IAgent }>response.data;
      if (agent) {
        commit(agentMutationsTypes.resetAgent());
        commit(agentMutationsTypes.setAgent(agent));
        // agent.subscriptions.push(`#${agent._id}`);

        agent.subscriptions.forEach(subscription => {
          const sub = centrifuge.newSubscription(subscription);
          sub
            .on('publication', async function (ctx: any) {
              switch (ctx.data.event) {
                case SOCKET_EVENTS.DUPLICATED_SESSION:
                  await new Promise(resolve => setTimeout(resolve, 3000));
                  break;
                case SOCKET_EVENTS.TRANSFERRED_CONVERSATION:
                  const { agent, conversation } = ctx.data.payload;
                  commit(
                    agentMutationsTypes.setAgentOpenConversations([
                      conversation,
                    ]),
                  );
                  commit(
                    agentMutationsTypes.setAgentTransferredConversation({
                      agent,
                      conversation,
                    }),
                  );
                  break;
                case SOCKET_EVENTS.NEW_ENTRY:
                  const newEntry: ConversationNewEntryQueue = ctx.data.payload;
                  dispatch(agentActionsTypes.playSound(NEW_CONVERSATION_SOUND));
                  commit(agentMutationsTypes.setNewEntryInQueue(newEntry));
                  break;
                case SOCKET_EVENTS.AWATING_RESPONSE:
                  const aConversation = ctx.data;
                  // dispatch(agentActionsTypes.playSound(NEW_CONVERSATION_SOUND));
                  if (
                    !rootState.conversation!.currentConversation ||
                    !rootState.conversation!.currentConversation._id ||
                    rootState.conversation!.currentConversation._id ===
                      aConversation._id
                  ) {
                    commit(
                      conversationMutationsTypes.setCurrentConversation(
                        aConversation,
                      ),
                    );
                  }
                  commit(
                    conversationMutationsTypes.setConversationReturn(
                      aConversation,
                    ),
                  );
                  commit(
                    agentMutationsTypes.removeConversationClosed(
                      aConversation._id,
                    ),
                  );
                  commit(
                    agentMutationsTypes.setAgentOpenConversations([
                      aConversation,
                    ]),
                  );
                  break;
                case SOCKET_EVENTS.REMOVE_ENTRY:
                  const rEntry = ctx.data.payload;
                  commit(agentMutationsTypes.setRemoveEntryOfQueue(rEntry));
                  break;

                case SOCKET_EVENTS.NEW_MESSAGE:
                  const nMessage = ctx.data.payload;
                  const currentConversation =
                    rootState.conversation!.currentConversation;

                  if (nMessage.input.agent === store.state.agent!.agent._id) {
                    dispatch(agentActionsTypes.playSound(NEW_MESSAGE_SOUND));
                  }

                  if (
                    !currentConversation ||
                    currentConversation._id !== nMessage.conversation
                  ) {
                    commit(
                      agentMutationsTypes.setMessageInOpenConversation(
                        nMessage,
                      ),
                    );
                  }

                  if (
                    currentConversation &&
                    currentConversation._id === nMessage.conversation
                  ) {
                    commit(
                      agentMutationsTypes.setMessageInOpenConversation(
                        nMessage,
                      ),
                    );
                    commit(
                      conversationMutationsTypes.setMessageInConversation(
                        nMessage,
                      ),
                    );
                    commit(
                      agentMutationsTypes.setNewInteraction(nMessage.input),
                    );
                  }
                  break;
                case SOCKET_EVENTS.UPDATE_CONVERSATION: {
                  const uConversation = ctx.data.payload;
                  if (
                    rootState.conversation!.currentConversation &&
                    rootState.conversation!.currentConversation._id &&
                    rootState.conversation!.currentConversation._id ===
                      uConversation.conversation
                  ) {
                    commit(
                      agentMutationsTypes.setUpdateNewStateConversation(
                        uConversation,
                      ),
                    );
                  }
                  break;
                }
                case SOCKET_EVENTS.DELETE_CONVERSATION: {
                  const dConversation = ctx.data.payload;
                  if (
                    rootState.conversation!.currentConversation &&
                    rootState.conversation!.currentConversation._id &&
                    rootState.conversation!.currentConversation._id ===
                      dConversation.conversation._id
                  ) {
                    commit(
                      agentMutationsTypes.removeConversationOpen(
                        dConversation.conversation._id,
                      ),
                    );
                    commit(
                      agentMutationsTypes.setCurrentConversationInConversations(
                        <Conversation>{},
                      ),
                    );
                    commit(
                      conversationMutationsTypes.setCurrentConversation(
                        <Conversation>{},
                      ),
                    );
                    commit(
                      conversationMutationsTypes.setError({
                        message: 'Se ha perdido la comunicación con el cliente',
                        visibleError: true,
                      }),
                    );
                    commit(agentMutationsTypes.setLoadingQueue(true));
                    setInterval(
                      () => commit(agentMutationsTypes.setLoadingQueue(false)),
                      500,
                    );
                  }
                  if (
                    !rootState.conversation!.currentConversation ||
                    !rootState.conversation!.currentConversation._id ||
                    rootState.conversation!.currentConversation._id !==
                      dConversation.conversation._id
                  ) {
                    commit(
                      agentMutationsTypes.setRemoveEntryOfQueue(dConversation),
                    );
                  }
                  break;
                }
                case SOCKET_EVENTS.UPDATE_CONVERSATION: {
                  const upConversation = ctx.data.payload;
                  commit(
                    agentMutationsTypes.setUpdateInteraction(upConversation),
                  );
                  break;
                }

                case SOCKET_EVENTS.UPDATE_INTERACTION: {
                  const entry = ctx.data.payload;
                  commit(agentMutationsTypes.setUpdateInteraction(entry));

                  break;
                }
                case SOCKET_EVENTS.NEW_ENTRY_AUTO: {
                  const newEntryAuto: ConversationNewEntryQueue =
                    ctx.data.payload;
                  commit(agentMutationsTypes.setNewEntryInAuto(newEntryAuto));
                  break;
                }
                case SOCKET_EVENTS.NEW_MESSAGE_AUTO: {
                  const nMessageAuto = ctx.data.payload;
                  if (
                    !rootState.conversation!.currentConversation ||
                    !rootState.conversation!.currentConversation._id ||
                    rootState.conversation!.currentConversation._id !==
                      nMessageAuto.conversation
                  ) {
                    commit(
                      agentMutationsTypes.setMessageInOpenConversation(
                        nMessageAuto,
                      ),
                    );
                  }
                  if (
                    rootState.conversation!.currentConversation &&
                    rootState.conversation!.currentConversation._id &&
                    rootState.conversation!.currentConversation._id ===
                      nMessageAuto.conversation
                  ) {
                    commit(
                      agentMutationsTypes.setMessageInOpenConversation(
                        nMessageAuto,
                      ),
                    );
                    commit(
                      conversationMutationsTypes.setMessageInConversation(
                        nMessageAuto,
                      ),
                    );
                    commit(
                      agentMutationsTypes.setNewInteraction(nMessageAuto.input),
                    );
                  }
                  break;
                }
                case 'DROPI:NEW_MESSAGE':
                  {
                    /**
                     * @description
                     * 1. Get the message from the payload thats is the order from dropi the most important is phonenumber
                     * 2. check disitrubution_company, status
                     * 3. check if filters are active
                     * 4. if filter is active check if the message is in the filter and still match with the filter let it pass if not remove it, if match with the filter add it to the list
                     * 5. if filter is not active let it pass
                     */ const nMessageDropi = ctx.data.payload;
                    // filters
                    const { filterSelectedOpen } = store.state.agent!;
                    const { filterSelectedQueue } = store.state.agent!;
                    const { filterSelectedClose } = store.state.agent!;
                    const { filterSelectedAuto } = store.state.agent!;

                    // conversations
                    const { agentOpenConversationsFiltered } =
                      store.state.agent!;
                    const { agentQueueFiltered } = store.state.agent!;
                    const { agentCloseConversationsFiltered } =
                      store.state.agent!;
                    const { agentAutoConversationsFiltered } =
                      store.state.agent!;
                    const filtersAndLists = [
                      {
                        filterSelected: filterSelectedOpen,
                        agentFiltered: agentOpenConversationsFiltered,
                        mutationAdd:
                          agentMutationsTypes.setNewEntryInOpenFiltered,
                        mutationRemove:
                          agentMutationsTypes.removeConversationOpenFiltered,
                      },
                      {
                        filterSelected: filterSelectedQueue,
                        agentFiltered: agentQueueFiltered,
                        mutationAdd:
                          agentMutationsTypes.setNewEntryInQueueFiltered,
                        mutationRemove:
                          agentMutationsTypes.removeConversationQueueFiltered,
                      },
                      {
                        filterSelected: filterSelectedClose,
                        agentFiltered: agentCloseConversationsFiltered,
                        mutationAdd:
                          agentMutationsTypes.setNewEntryInCloseFiltered,
                        mutationRemove:
                          agentMutationsTypes.removeConversationClosedFiltered,
                      },
                      {
                        filterSelected: filterSelectedAuto,
                        agentFiltered: agentAutoConversationsFiltered,
                        mutationAdd:
                          agentMutationsTypes.setNewEntryInAutoFiltered,
                        mutationRemove:
                          agentMutationsTypes.removeConversationAutoFiltered,
                      },
                    ];

                    filtersAndLists.forEach(filterAndList => {
                      const {
                        filterSelected,
                        agentFiltered,
                        mutationAdd,
                        mutationRemove,
                      } = filterAndList;
                      if (filterSelected.value !== 'all') {
                        const filterSelectedConfig = filterSelected.config.map(
                          config => ({
                            config: config.selectedOption,
                            name_filter: config.name_filter,
                          }),
                        );

                        const matchFilter = filterSelectedConfig.some(
                          config =>
                            config.config[0] === nMessageDropi.status ||
                            config.config[0] ===
                              nMessageDropi.distribution_company,
                        );
                        const isInFilter = agentFiltered.find(
                          conversation =>
                            conversation.currentConsumer ===
                            nMessageDropi.phone,
                        );

                        if (matchFilter && !isInFilter) {
                          commit(mutationAdd(nMessageDropi.conversation));
                        }

                        if (!matchFilter && isInFilter) {
                          commit(mutationRemove(nMessageDropi));
                        }
                      }
                    });

                    // check if filterSelectedOpen is active
                    if (filterSelectedOpen.value !== 'all') {
                      // extract filter config from filterSelectedOpen
                      const filterSelectedOpenConfig =
                        filterSelectedOpen.config.map((config: any) => {
                          return {
                            config: config.selectedOption,
                            name_filter: config.name_filter,
                          };
                        });
                      // check if the filter setted is the same as the message
                      const matchFilter = filterSelectedOpenConfig.some(
                        (config: any) => {
                          return (
                            config.config === nMessageDropi.status ||
                            config.config === nMessageDropi.distribution_company
                          );
                        },
                      );
                      const isInFilter = agentOpenConversationsFiltered.find(
                        (conversation: Conversation) => {
                          return (
                            conversation.currentConsumer === nMessageDropi.phone
                          );
                        },
                      );
                      if (matchFilter && !isInFilter) {
                        commit(
                          agentMutationsTypes.setNewEntryInOpenFiltered(
                            nMessageDropi,
                          ),
                        );
                      }

                      if (!matchFilter && isInFilter) {
                        commit(
                          agentMutationsTypes.removeConversationOpenFiltered(
                            nMessageDropi,
                          ),
                        );
                      }
                    }
                  }
                  break;
                default:
                  break;
              }
            })
            .subscribe();

          // listen changes when an interactions is changed
        });
        // set loadingConversation to true
        commit(agentMutationsTypes.loadingConversation(true));
        const promises = Promise.all([
          dispatch(
            agentActionsTypes.loadAgentOpenConversations({
              agent_id: agent._id,
              page: 0,
              limit: 50,
            }),
          ),
          dispatch(
            agentActionsTypes.loadAgentCloseConversations({
              agent_id: agent._id,
              page: 0,
            }),
          ),
          dispatch(
            agentActionsTypes.loadAgentQueues({
              subscriptions: agent.subscriptions,
              page: 0,
            }),
          ),
          dispatch(
            agentActionsTypes.loadAgentAutoConversations({
              subscriptions: agent.subscriptions,
              page: 0,
            }),
          ),
        ]);
        // wait for all promises to be resolved before setting loadingConversation to false
        await promises.then(() => {
          commit(agentMutationsTypes.loadingConversation(false));
        });
        router.push({ name: 'agent' });
      }
    });
  },
  async playSound({}, { payload }) {
    let audio = new Audio(payload);
    await audio.play();
    return 1;
  },
  async loadAgentOpenConversations({ commit }, { payload }) {
    try {
      commit(agentMutationsTypes.setLoadingOpen(true));
      const response = await Axios.get(
        `${BASE_URL_ORCHESTATOR}/agent/${payload.agent_id}/conversation/open`,
        {
          params: {
            limit: payload.limit,
            page: payload.page,
            sort: payload.sort || -1,
          },
        },
      );

      const { conversations, total } = response.data;
      commit(agentMutationsTypes.setLoadingOpen(false));
      if (Array.isArray(conversations)) {
        commit(agentMutationsTypes.setAgentOpenConversations(conversations));

        if (conversations.length > 0) {
          commit(
            agentMutationsTypes.setPageOpenConversation(
              store.state.agent!.pageOpen + 1,
            ),
          );
        }

        if (total !== undefined) {
          commit(agentMutationsTypes.setAgentOpenTotalConversations(total));
        }
      }
    } catch (error) {
      console.error('Error loading agent open conversations:', error);
      // Handle error if needed
    }
  },
  async loadAgentCloseConversations({ commit }, { payload }) {
    try {
      commit(agentMutationsTypes.setLoadingClose(true));
      const response = await Axios.get(
        `${BASE_URL_ORCHESTATOR}/agent/${payload.agent_id}/conversation/close`,
        {
          params: {
            page: payload.page,
            sort: payload.sort,
          },
        },
      );

      const { conversations, total } = response.data;
      commit(agentMutationsTypes.setLoadingClose(false));
      if (Array.isArray(conversations)) {
        commit(agentMutationsTypes.setAgentCloseConversations(conversations));

        if (conversations.length > 0) {
          commit(
            agentMutationsTypes.setPageCloseConversation(
              store.state.agent!.pageClose + 1,
            ),
          );
        }

        if (total !== undefined) {
          commit(agentMutationsTypes.setAgenCloseTotalConversations(total));
        }
      }
    } catch (error) {
      console.error('Error loading agent close conversations:', error);
      // Handle error if needed
    }
  },
  async loadAgentQueues({ commit }, { payload }) {
    commit(agentMutationsTypes.setLoadingQueue(true));

    try {
      // Remove entries with "#" from subscriptions
      const subscriptions = payload.subscriptions.filter(
        sub => !sub.includes('#'),
      );

      const response = await Axios.get(`${BASE_URL_MANAGER}/queue`, {
        params: {
          subscriptions,
          page: payload.page,
          sort: payload.sort,
        },
      });

      const { queues, total } = response.data;

      if (payload.restartQueue) {
        commit(agentMutationsTypes.resetQueue([]));
        commit(agentMutationsTypes.setQueueTotal(0));
      }

      if (Array.isArray(queues) && queues.length > 0) {
        commit(agentMutationsTypes.setQueue(queues));
        commit(
          agentMutationsTypes.setPageQueueConversation(
            store.state.agent!.pageQueue + 1,
          ),
        );
        commit(agentMutationsTypes.setQueueTotal(total));
      } else {
        agentMutationsTypes.setPageQueueConversation(
          store.state.agent!.pageQueue - 1,
        );
      }
    } catch (error) {
      console.error('Error loading agent queues:', error);
      // Handle error if needed
    } finally {
      commit(agentMutationsTypes.setLoadingQueue(false));
    }
  },
  async loadAgentAutoConversations({ commit, state }, { payload }) {
    try {
      commit(agentMutationsTypes.setLoadingAuto(true));
      const project = store.state.user!.user.company!.projects[0] || null; // Ensure project exists

      if (!project) {
        console.warn('No project found.');
        return; // Exit the function if project is not available
      }
      const response = await Axios.get(
        `${BASE_URL_ORCHESTATOR}/agent/${project._id}/conversation/auto`,
        {
          params: {
            limit: payload.limit,
            page: payload.page,
            sort: payload.sort,
          },
        },
      );

      const { conversations, total } = response.data;
      commit(agentMutationsTypes.setLoadingAuto(false));
      if (Array.isArray(conversations) && conversations.length > 0) {
        commit(agentMutationsTypes.setAgentAutoConversations(conversations));
        commit(
          agentMutationsTypes.setPageAutoConversation(
            store.state.agent!.pageAuto + 1,
          ),
        );

        if (total !== undefined) {
          commit(agentMutationsTypes.setAgentAutoTotalConversations(total));
        }
      }
    } catch (error) {
      console.error('Error loading agent auto conversations:', error);
      // Handle error if needed
    }
  },
  async loadActualInteractions({ commit }, { payload }) {
    const response = await Axios(
      `${BASE_URL_ORCHESTATOR}/conversation/convesations/${payload.conversationId}/${payload.page}`,
    );
    if (payload.page === 0) {
      commit(
        agentMutationsTypes.setInteractions(
          response.data.interactions.reverse(),
        ),
      );
      commit(agentMutationsTypes.setTotalPages(response.data.pagecount));
    } else {
      //leonardo maria, esto es lo que dañab alo del orden cuando le dabas en cargar mas, se resuelvio poniendole el reverse
      commit(
        agentMutationsTypes.loadMoreInteractions(
          response.data.interactions.reverse(),
        ),
      );
    }
    commit(agentMutationsTypes.setActualPage(Number(response.data.pagenum)));
  },

  orderConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.sortConversation(payload));
  },
  orderCloseConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.sortCloseConversation(payload));
  },
  orderAutoConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.sortAutoConversation(payload));
  },
  orderQueuesConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.sortQueueConversation(payload));
  },
  setOrderCloseConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.setOrderCloseConversation(payload));
  },
  setOrderConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.setOrderOpenConversation(payload));
  },
  setOrderQueuesConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.setOrderQueueConversation(payload));
  },
  setOrderAutoConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.setOrderAutoConversation(payload));
  },
  setPageCloseConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.setPageCloseConversation(payload));
  },
  setPageOpenConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.setPageOpenConversation(payload));
  },
  setPageQueuesConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.setPageQueueConversation(payload));
  },

  setPageAutoConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.setPageAutoConversation(payload));
  },

  updateTotalConversationsOpen({ commit }, { payload }) {
    commit(agentMutationsTypes.setAgentOpenTotalConversations(payload));
  },

  resetQueueConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.resetQueue([]));
  },
  resetOpenConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.resetOpen([]));
  },

  resetAutoConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.resetAuto([]));
  },
  resetCloseConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.resetCloseConversation());
  },
  setTabSelected({ commit }, { payload }) {
    commit(agentMutationsTypes.setTabSelected(payload));
  },
  setFilteredAutoConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.setFilteredConversationsAuto(payload));
  },
  setFilteredConversationsOpen({ commit }, { payload }) {
    commit(agentMutationsTypes.setFilteredConversationsOpen(payload));
  },
  setFilteredCloseConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.setFilteredConversationsClose(payload));
  },
  setFilteredQueuesConversations({ commit }, { payload }) {
    commit(agentMutationsTypes.setFilteredConversationsQueue(payload));
  },

  filterSelectedOpen({ commit }, { payload }) {
    commit(agentMutationsTypes.filterSelectedOpen(payload));
  },
  filterSelectedClose({ commit }, { payload }) {
    commit(agentMutationsTypes.filterSelectedClose(payload));
  },
  filterSelectedQueues({ commit }, { payload }) {
    commit(agentMutationsTypes.filterSelectedQueue(payload));
  },
  filterSelectedAuto({ commit }, { payload }) {
    commit(agentMutationsTypes.filterSelectedAuto(payload));
  },
};

export default actions;
