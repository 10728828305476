export interface FilterState {
  filters_agent: Filter[];
  filters_project: Filter[];
  currentView: string;
}

export interface Filter {
  label: string;
  value: string;
  config: any;
  name: string;
  menuVisible?: boolean;
  menuPosition?: {
    top: string;
    left: string;
  };
}

export const filterInitialState: FilterState = {
  filters_agent: [],
  filters_project: [],
  currentView: 'messages',
};

export type ExtendedFilterState = { filters?: FilterState };
