import Axios from 'axios';
import { DefineTypes, DefineActions } from '@/app/store/store.helper';
import { RootState } from '@/app/store/root.models';
import { BASE_URL_MANAGER } from '@/config';
import { AuthState, AuthTokenName } from './auth.models';
import { authMutationsTypes } from './auth.mutations';
import { parseJwt } from './auth.helper';
import { userMutationsTypes } from '../user/user.mutations';
import { agentMutationsTypes } from '../agent/agent.mutations';
import router from '@/app/router';
import { InstanceVue } from '@/main';
import { store } from '@/app/store';
import { userTypes } from '../../modules/user';

export interface AuthActions {
  login: { username: string; password: string };
  setToken: string;
  logout: undefined;
  initExpirationChecker: {
    expiration: number;
    username: string;
    password: string;
  };
}

export const authActionsTypes: DefineTypes<AuthActions> = {
  login: payload => ({ type: 'login', payload }),
  setToken: payload => ({ type: 'setToken', payload }),
  logout: () => ({ type: 'logout' }),
  initExpirationChecker: payload => ({
    type: 'initExpirationChecker',
    payload,
  }),
};

const actions: DefineActions<AuthActions, AuthState, RootState> = {
  async login({ commit, dispatch }, { payload }) {
    try {
      commit(authMutationsTypes.setAuthLoading(true));
      const resp = await Axios.post(`${BASE_URL_MANAGER}/auth/login`, payload);
      const { token } = <{ token: string }>resp.data;
      if (!token) return false;
      // Configura el token en Axios antes de hacer cualquier solicitud adicional
      Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      await dispatch(authActionsTypes.setToken(token));
      commit(authMutationsTypes.setAuthLoading(false));
      const decode = parseJwt(token);

      if (Object.keys(decode).length) {
        commit(userMutationsTypes.setUser({ ...decode, login: true }));
        // Llama a la acción de carga de usuario y evita redirecciones innecesarias
        await store.dispatch(userTypes.actions.loadUser(decode._id));

        return true;
      }

      return false;
    } catch (error) {
      const instance: any = InstanceVue;
      instance.$notification.warning({
        placement: 'bottomRight',
        message: 'Usuario o Contraseña incorrectos',
        description:
          'El usuario o contraseña ingresados no son válidos o es posible que tu agente esté deshabilitado, por favor intenta de nuevo o contacta con tu administrador.',
      });
      commit(authMutationsTypes.setAuthLoading(false));
      return false;
    }
  },

  setToken({}, { payload }) {
    if (payload) {
      let token: any[] = [];
      let token_storage: string = '';
      token_storage = token[0] = payload.split(' ')[1];
      localStorage.setItem(AuthTokenName, token_storage);
    } else {
      localStorage.removeItem(AuthTokenName);
    }
  },
  async logout({ commit, dispatch }) {
    await Axios.post(`${BASE_URL_MANAGER}/auth/logout`, {
      _id: store.state.user!.user._id,
    });
    await dispatch(authActionsTypes.setToken(''));
    commit(agentMutationsTypes.resetAgent());
    commit(
      userMutationsTypes.setUser({
        _id: '',
        login: false,
        createdAt: new Date(),
      }),
    );
    if (router.currentRoute.name !== 'login') {
      window.location.href = '/login';
    }
  },
  initExpirationChecker({ commit, dispatch }, { payload }) {
    const expiresIn = (payload.expiration - Date.now() / 1000) * 1000;
    setTimeout(async () => {
      console.warn('Session expired, refreshing');
      await dispatch(
        authActionsTypes.login({
          password: payload.password,
          username: payload.username,
        }),
      );
    }, expiresIn - 2000);
  },
};

export default actions;
