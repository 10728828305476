import { DefineTypes, DefineMutations } from '@/app/store/store.helper';
import { RootState } from '@/app/store/root.models';
import { Filter, FilterState } from './filters.models';

export interface FilterMutations {
  setFilterAgent: Filter[];
  setFiltersProject: Filter[];
  pushNewFilterAgent: Filter;
  pushNewFIlterProject: Filter;
  removeFilterAgent: string;
  removeFilterProject: string;
  setCurrentView: string;
  setCloseMenu: void;
  setOpenMenu: {
    index: string;
    value: boolean;
    left: string;
    top: string;
  };
}

export const filterMutationsTypes: DefineTypes<FilterMutations> = {
  setFilterAgent: payload => ({ type: 'setFilterAgent', payload }),
  setFiltersProject: payload => ({ type: 'setFiltersProject', payload }),
  pushNewFilterAgent: payload => ({ type: 'pushNewFilterAgent', payload }),
  pushNewFIlterProject: payload => ({
    type: 'pushNewFIlterProject',
    payload,
  }),
  removeFilterAgent: payload => ({ type: 'removeFilterAgent', payload }),
  removeFilterProject: payload => ({ type: 'removeFilterProject', payload }),
  setCurrentView: payload => ({ type: 'setCurrentView', payload }),
  setCloseMenu: payload => ({ type: 'setCloseMenu', payload }),
  setOpenMenu: payload => ({ type: 'setOpenMenu', payload }),
};

const mutations: DefineMutations<FilterMutations, FilterState, RootState> = {
  setFilterAgent(state, { payload }) {
    state.filters_agent = payload;
  },
  setFiltersProject(state, { payload }) {
    state.filters_project = payload;
  },

  pushNewFilterAgent(state, { payload }) {
    payload.menuVisible = false;
    state.filters_agent.push(payload);
    // order by name az
    state.filters_agent.sort((a, b) => a.name.localeCompare(b.name));
  },

  pushNewFIlterProject(state, { payload }) {
    state.filters_project.push(payload);
    // order by name asc
    state.filters_project.sort((a, b) => a.name.localeCompare(b.name));
  },

  removeFilterAgent(state, { payload }) {
    state.filters_agent = state.filters_agent.filter(
      item => item.value !== payload,
    );
  },

  removeFilterProject(state, { payload }) {
    state.filters_project = state.filters_project.filter(
      item => item.value !== payload,
    );
  },

  setCurrentView(state, { payload }) {
    state.currentView = payload;
  },

  setCloseMenu(state, { payload }) {
    state.filters_agent.forEach(item => {
      item.menuVisible = false;
    });
  },

  setOpenMenu(state, { payload }) {
    const filter = state.filters_agent.findIndex(
      item => item.value === payload.index,
    );
    if (filter !== -1) {
      state.filters_agent[filter].menuVisible = payload.value;
      state.filters_agent[filter].menuPosition = {
        top: payload.top,
        left: payload.left,
      };
    }
  },
};

export default mutations;
