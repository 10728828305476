export interface UserState {
  user: IUser;
  loading: boolean;
  authenticated?: boolean;
  redirect?: boolean;
  project: IProject;
}

export enum UserRole {
  AGENT = 'AGENT',
  ADMIN = 'ADMIN',
  BASE = 'BASE',
}

export interface IUser {
  _id: string;
  login: boolean;
  role?: UserRole;
  name?: {
    firstName: string;
    lastName: string;
  };
  exp?: any;
  company?: ICompany;
  password?: any;
  createdAt: Date;
}

export type ICompany = {
  _id: string;
  name: string;
  projects: IProject[];
};

export type IProject = {
  _id: string;
  country: string;
  bussinesStatusList: any[];
  dropiUsers: any[];
  accessToken: string;
  phone_number_id: string;
  bussinnes_account_id: string;
  integrator?: string;
  selectedWhatsappNumber?: any;
};

export type ExtendedUserState = { user?: UserState };

export const initialUserState: UserState = {
  user: { _id: '', login: false, createdAt: new Date() },
  loading: false,
  authenticated: false,
  redirect: false,
  project: {
    _id: '',
    country: '',
    bussinesStatusList: [],
    dropiUsers: [],
    accessToken: '',
    phone_number_id: '',
    bussinnes_account_id: '',
  },
};
